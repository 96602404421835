import * as Yup from "yup";

const useValidation = () => {
    const setValidation = (values) => {
        const rules = {};
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                const type = values[key];
                const value = key;

                switch (true) {
                    case type == "date":
                        rules[value] = Yup.date(
                            `${value} es debe ser una fecha`
                        ).required("Requerido");
                        break;

                    default:
                        break;
                }
            }
        }

        const Validation = Yup.object().shape(rules);
        return Validation;
    };
    return { setValidation };
};

export default useValidation;
