import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { useStore } from "../../store/StoreProvider";

const ProtectedLogin = () => {
    const { auth, urlRequired } = useStore();

    const navegateTo = urlRequired ? urlRequired : "/dashboard";

    return !auth ? <Outlet /> : <Navigate to={navegateTo} />;
};

export default ProtectedLogin;
