const useStorage = () => {
    const setToken = (token) => {
        return localStorage.setItem("token", token);
    };
    const getToken = (token) => {
        return localStorage.getItem("token");
    };
    const deleteToken = () => {
        return localStorage.removeItem("token");
    };

    const setUser = (user) => {
        return localStorage.setItem("user", user);
    };
    const getUser = (user) => {
        return localStorage.getItem("user");
    };
    const deleteUser = () => {
        return localStorage.removeItem("user");
    };

    const setItem = (key, value) => {
        return localStorage.setItem(key, value);
    };
    const getItem = (key) => {
        return localStorage.getItem(key);
    };
    const deleteItem = (key) => {
        return localStorage.removeItem(key);
    };

    return {
        setToken,
        getToken,
        deleteToken,
        setUser,
        getUser,
        deleteUser,
        setItem,
        getItem,
        deleteItem,
    };
};

export default useStorage;
