import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import StoreProvider from "./store/StoreProvider";

const Index = () => {
    return (
        <StoreProvider>
            <App />
        </StoreProvider>
    );
};

ReactDOM.render(<Index />, document.getElementById("app"));
