import React, { useEffect, useState } from "react";
import useQuerys from "../../hooks/useQuerys";
import { Formik, useFormik } from "formik";
import useFormData from "../../hooks/useFormData";
import { Form, Modal } from "react-bootstrap";
import Input from "../util/form/Input";
import { FaDownload } from "react-icons/fa";
import useValidation from "./SolicitudReporteValidation";
import Select from "../util/form/Select";
import { useStore } from "../../store/StoreProvider";

const SolicitudReporte = ({
    url,
    data,
    fileName,
    filters,
    modal,
    setModal,
    proyectos,
    title,
    buttonText = "Generar",
}) => {
    const { user } = useStore();
    const { getReport } = useQuerys();
    const { setData } = useFormData();
    const { setValidation } = useValidation();
    const [values, setValues] = useState({});
    const initialValues = {};
    const rules = {};

    useEffect(() => {
        if (modal) {
            let values = {};
            filters.map((filter) => {
                switch (filter) {
                    case "fechas":
                        const curr = new Date();
                        const hasta = curr.toISOString().substr(0, 10);
                        curr.setDate(curr.getDate() - 30);
                        const desde = curr.toISOString().substr(0, 10);
                        initialValues.desde = desde;
                        initialValues.hasta = hasta;
                        rules.desde = "date";
                        rules.hasta = "date";
                        values.desde = desde;
                        values.hasta = hasta;
                        break;

                    case "proyecto":
                        initialValues.proyecto = 0;
                        if (typeof data.proyecto.id != "undefined") {
                            if (url.includes("http")) {
                                initialValues.proyecto = data.proyecto.slug;
                                values.proyecto = data.proyecto.slug;
                            } else {
                                initialValues.proyecto = data.proyecto.id;
                                values.proyecto = data.proyecto.id;
                            }
                        }
                        break;

                    default:
                        break;
                }
            });
            setValues(values);
            console.log(initialValues);
        }
    }, [modal]);

    /* const formik = useFormik({
        initialValues: initialValues,
        validationSchema: setValidation(rules),
        onSubmit: async (values) => {
            const data = setData(values);
            getReport(url, data, fileName, () => {
                setModal(false);
            });
        },
    }); */

    const onSubmit = async (values) => {
        const data = setData(values);
        getReport(url, data, fileName, () => {
            setModal(false);
        });
    };

    const getFilter = (filter, formik) => {
        let component = "";
        switch (filter) {
            case "fechas":
                component = (
                    <>
                        <div className="col-md-6">
                            <Input
                                name="desde"
                                label="Desde"
                                formik={formik}
                                type="date"
                            ></Input>
                        </div>
                        <div className="col-md-6">
                            <Input
                                name="hasta"
                                label="Hasta"
                                formik={formik}
                                type="date"
                            ></Input>
                        </div>
                    </>
                );
                break;

            case "proyecto":
                let value = "id";
                if (url.includes("http")) {
                    value = "slug";
                }
                component = (
                    <>
                        <div className="col-md-12">
                            <Select
                                name="proyecto"
                                label="Proyecto"
                                options={proyectos}
                                optionKey="id"
                                optionValue={value}
                                optionText="nombre"
                                zeroOption={
                                    user.role == "dios" ? "Todos" : null
                                }
                                formik={formik}
                            ></Select>
                        </div>
                    </>
                );
                break;

            default:
                break;
        }

        return component;
    };

    return (
        <Modal
            show={modal}
            onHide={() => {
                setModal(false);
            }}
            dialogClassName="modal-70w"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>{title}</Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={setValidation(rules)}
                    values={values}
                    onSubmit={onSubmit}
                >
                    {(formik) => (
                        <Form id="clientes-form" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                {filters.map((filter) => {
                                    return (
                                        <div className="row" key={filter}>
                                            {getFilter(filter, formik)}
                                        </div>
                                    );
                                })}
                            </div>

                            <button
                                className=" btn btn-lg btn-primary btn-guardar mt-4 "
                                type="submit"
                            >
                                {buttonText}
                                <FaDownload></FaDownload>
                            </button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default SolicitudReporte;
