import React from "react";
import Header from "./Header";

import { useStore } from "../../store/StoreProvider";

const Menu = () => {
    return (
        <div>
            <Header></Header>
        </div>
    );
};

export default Menu;
