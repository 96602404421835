import Cookies from "js-cookie";

const types = {
    urlRequired: "urlRequired",
    authLogin: "authLogin",
    authLogout: "authLogout",
    menuToggle: "menuToggle",
    menuShow: "menuShow",
    menuShow: "menuHide",
    loadingShow: "loadingShow",
    loadingHide: "loadingHide",
    messageShow: "messageShow",
    setThemeDark: "setThemeDark",
    setThemeLight: "setThemeLight",
};

const initialStore = {
    auth: false,
    user: {},
    token: false,
    menu: false,
    loading: false,
    messagesLists: [],
    urlRequired: false,
    theme: "light",
};

const storeReducer = (state, action) => {
    switch (action.type) {
        case types.setThemeDark:
            return {
                ...state,
                theme: "dark",
            };

        case types.setThemeLight:
            return {
                ...state,
                theme: "light",
            };

        case types.authLogin:
            return {
                ...state,
                auth: true,
                user: action.payload.user,
                token: action.payload.token,
            };

        case types.authLogout:
            return {
                ...state,
                auth: false,
                user: {},
                token: false,
            };

        case types.urlRequired:
            return {
                ...state,
                urlRequired: action.payload,
            };

        case types.menuToggle:
            return {
                ...state,
                menu: !state.menu,
            };

        case types.menuShow:
            return {
                ...state,
                menu: true,
            };

        case types.menuHide:
            return {
                ...state,
                menu: false,
            };

        case types.loadingShow:
            return {
                ...state,
                loading: true,
            };

        case types.loadingHide:
            return {
                ...state,
                loading: false,
            };

        case types.messageShow:
            const id = Math.floor(Math.random() * 101 + 1);
            const { message, type } = action.payload;
            let title = "OK";
            switch (type) {
                case "danger":
                    title = "Error";
                    break;

                case "warning":
                    title = "Warning";
                    break;

                case "info":
                    title = "Info";
                    break;

                case "success":
                    title = "OK";
                    break;

                default:
                    break;
            }

            const toastProperties = {
                id,
                title: title,
                description: message,
                type: type,
                visible: true,
            };

            return {
                ...state,
                messagesLists: [...state.messagesLists, toastProperties],
            };

        default:
            return state;
    }
};

export { initialStore, types };
export default storeReducer;
