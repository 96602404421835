import React, { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import Input from "../util/form/Input";
import InputFile from "../util/form/InputFile";
import Switch from "../util/form/Switch";
import { FaSave } from "react-icons/fa";

const EmpresaForm = ({ formik }) => {
    const nombre = useRef(null);
    const file = useRef(null);

    useEffect(async () => {
        nombre.current.focus();
    }, []);

    return (
        <div>
            <Form id="clientes-form" onSubmit={formik.handleSubmit}>
                <Input
                    name="nombre"
                    label="Nombre"
                    type="text"
                    formik={formik}
                    ref={nombre}
                />

                <InputFile
                    name="logo"
                    label="logo"
                    ref={file}
                    formik={formik}
                />

                <Switch name="activo" label="Activa" formik={formik}></Switch>

                <button
                    className=" btn btn-lg btn-primary btn-guardar mt-4 "
                    type="submit"
                >
                    Guardar
                    <FaSave></FaSave>
                </button>
            </Form>
        </div>
    );
};

export default EmpresaForm;
