import React from "react";
import { Form } from "react-bootstrap";

const Switch = React.forwardRef(({ name, label, formik }, ref) => {
    return (
        <Form.Group controlId={name} className="mb-3 mt-3 position-relative">
            <Form.Check
                type="switch"
                ref={ref}
                label={label}
                checked={formik.values[name]}
                onChange={(e) => formik.setFieldValue(name, e.target.checked)}
            ></Form.Check>
        </Form.Group>
    );
});

export default Switch;
