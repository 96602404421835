import * as Yup from "yup";

const Validation = Yup.object().shape({
    proyecto_id: Yup.number("No has seleccionado una empresa")
        .required("Requerido")
        .min(1, "No has seleccionado un proyecto"),

    archivo: Yup.mixed()
        .required("Requerido")
        .test("fileSize", "El archivo es muy grande", (value) => {
            if (typeof value != "undefined") {
                return value.size <= 2000000;
            } else {
                return true;
            }
        }),
});

export default Validation;
