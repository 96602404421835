import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import "./Login.scss";
import useAuth from "../../hooks/useAuth";
import Input from "../util/form/Input";
import { Form } from "react-bootstrap";

const Login = () => {
    const { logIn } = useAuth();

    const email = useRef(null);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: (values) => {
            logIn(values.email, values.password);
        },
    });

    useEffect(() => {
        email.current.focus();
    }, []);

    return (
        <div className="form-signin">
            {/* <div className="row">
                <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4"> */}
            <Form noValidate onSubmit={formik.handleSubmit}>
                <h1 className="h3 mb-3 fw-normal">Acceso</h1>
                <Input
                    name="email"
                    label="Email"
                    type="email"
                    formik={formik}
                    ref={email}
                />

                <Input
                    name="password"
                    label="Contraseña"
                    type="password"
                    formik={formik}
                />
                <button className="w-100 btn btn-lg btn-primary" type="submit">
                    Entrar
                </button>
                {/* <p className="mt-5 mb-3 text-muted">&copy;</p> */}
            </Form>
            <div className="olvido">
                <Link to="/forgot-password">Olvidé mi contraseña</Link>
            </div>
            {/*  </div>
            </div> */}
        </div>
    );
};

export default Login;
