import React from "react";

import { FloatingLabel, Form } from "react-bootstrap";

const Select = React.forwardRef(
    (
        {
            name,
            label,
            options,
            optionKey,
            optionValue,
            optionText,
            onChange,
            zeroOption = null,
            multiple = false,
            formik,
        },
        ref
    ) => {
        let handleChange = (value) => {
            formik.setFieldValue(name, value);
        };
        if (onChange) {
            handleChange = onChange;
        }

        return (
            <Form.Group controlId={name} className="mb-3 position-relative">
                <FloatingLabel
                    label={label}
                    className={
                        formik.touched[name] && formik.errors[name]
                            ? "is-invalid"
                            : "is-valid"
                    }
                >
                    <Form.Select
                        onChange={(e) => {
                            formik.setFieldValue(name, e.target.value);
                            handleChange(e.target.value);
                        }}
                        value={formik.values[name]}
                        ref={ref}
                        multiple={multiple}
                    >
                        {zeroOption && <option>{zeroOption}</option>}

                        {options.map((option) => {
                            return (
                                <option
                                    key={option[optionKey]}
                                    value={option[optionValue]}
                                >
                                    {option[optionText]}
                                </option>
                            );
                        })}
                    </Form.Select>
                    {formik.touched[name] && formik.errors[name] ? (
                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors[name]}
                        </Form.Control.Feedback>
                    ) : null}
                </FloatingLabel>
            </Form.Group>
        );
    }
);

export default Select;
