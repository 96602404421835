const useCrypt = () => {
    const key = "p5dF619dSf4DF35";

    const crypt = (text) => {
        let cryptText = window.btoa(text);
        cryptText = cryptText.split("").reverse().join("");
        cryptText = cryptText.slice(0, 5) + key + cryptText.slice(5);
        return cryptText;
    };

    const uncrypt = (cryptText) => {
        let text = cryptText.replace(key, "");
        text = text.split("").reverse().join("");
        text = window.atob(text);
        return text;
    };

    return { crypt, uncrypt };
};

export default useCrypt;
