import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    useNavigate,
    Navigate,
} from "react-router-dom";
// import Cookies from "js-cookie";

import useStorage from "../hooks/useStorage";
import { types } from "../store/storeReducer";
import { useDispatch, useStore } from "../store/StoreProvider";
import ProtectedRoute from "./auth/ProtectedRoute";
import ProtectedLogin from "./auth/ProtectedLogin";

import Login from "./auth/login";
import Dashboard from "./application/Dashboard";
import NotFound from "./application/NotFound";

import Menu from "./application/Menu";
import Loading from "./util/Loading";
import Toast from "./util/toast/Toast";
import Empresas from "./empresas/Empresas";
import Clientes from "./clientes/Clientes";
import Usuarios from "./usuarios/Usuarios";
import Proyectos from "./proyectos/Proyectos";
import useCrypt from "../hooks/useCrypt";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import CambiaPass from "./usuarios/CambiaPass";

const App = () => {
    const { messagesLists } = useStore();
    const dispatch = useDispatch();
    const { uncrypt } = useCrypt();
    const { setToken, getToken, deleteToken, setUser, getUser, deleteUser } =
        useStorage();

    useEffect(() => {
        // deleteUser();
        // deleteToken();

        const user = getUser();
        const token = getToken();
        if (user) {
            // const in30Minutes = 1 / 48;
            setUser(user);
            setToken(token);
            // Cookies.set("user", user, {
            //     secure: true,
            //     expires: in30Minutes,
            // });
            // Cookies.set("token", token, {
            //     secure: true,
            //     expires: in30Minutes,
            // });

            dispatch({
                type: types.authLogin,
                payload: {
                    token: uncrypt(token),
                    user: JSON.parse(uncrypt(user)),
                },
            });
        } else {
            if (token) {
                dispatch({
                    type: types.authLogout,
                    payload: uncrypt(token),
                });
            }
        }
    }, []);

    return (
        <div className="App">
            <React.StrictMode>
                <Loading />

                <Toast
                    toastList={messagesLists}
                    position={"bottom-right"}
                    autoDelete={true}
                    dismissTime={6000}
                />

                <Router>
                    <Menu></Menu>
                    <div id="main-content" className="container-fluid">
                        <div className="row">
                            <main className="col-md-12">
                                <MyRoutes />
                            </main>
                        </div>
                    </div>
                </Router>
            </React.StrictMode>
        </div>
    );
};

const MyRoutes = () => {
    const { user } = useStore();

    const { pathname } = useLocation();
    const navegate = useNavigate();

    /*  useEffect(() => {
        console.log(pathname, user);
        setTimeout(() => {
            if (
                user &&
                !user.password_changed &&
                user.role != "dios" &&
                pathname != "/usuarios/cambia-clave" &&
                pathname != "/"
            ) {
                return navegate("/usuarios/cambia-clave");
            }
        }, 2000);
    }, [pathname, user]); */

    return (
        <Routes>
            <Route exact path="/" element={<ProtectedLogin />}>
                <Route exact path="/" element={<Login />}></Route>
            </Route>

            <Route exact path="/forgot-password" element={<ProtectedLogin />}>
                <Route
                    exact
                    path="/forgot-password"
                    element={<ForgotPassword />}
                ></Route>
            </Route>

            <Route
                exact
                path="/reset-password/:token"
                element={<ProtectedLogin />}
            >
                <Route
                    exact
                    path="/reset-password/:token"
                    element={<ResetPassword />}
                ></Route>
            </Route>

            <Route exact path="/dashboard" element={<ProtectedRoute />}>
                <Route exact path="/dashboard" element={<Dashboard />}></Route>
            </Route>

            <Route exact path="/empresas" element={<ProtectedRoute />}>
                <Route exact path="/empresas" element={<Empresas />}></Route>
            </Route>

            <Route exact path="/clientes" element={<ProtectedRoute />}>
                <Route exact path="/clientes" element={<Clientes />}></Route>
            </Route>

            <Route exact path="/usuarios" element={<ProtectedRoute />}>
                <Route exact path="/usuarios" element={<Usuarios />}></Route>
            </Route>

            <Route
                exact
                path="/usuarios/cambia-clave"
                element={<ProtectedRoute />}
            >
                <Route
                    exact
                    path="/usuarios/cambia-clave"
                    element={<CambiaPass />}
                ></Route>
            </Route>

            <Route exact path="/proyectos" element={<ProtectedRoute />}>
                <Route exact path="/proyectos" element={<Proyectos />}></Route>
            </Route>

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default App;
