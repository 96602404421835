import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import checkIcon from "./assets/check.svg";
import errorIcon from "./assets/error.svg";
import infoIcon from "./assets/info.svg";
import warningIcon from "./assets/warning.svg";

import "./Toast.scss";

const Toast = (props) => {
    const { toastList, position, autoDelete, dismissTime } = props;
    const [list, setList] = useState(toastList);
    const [images, setImages] = useState({
        success: checkIcon,
        danger: errorIcon,
        info: infoIcon,
        warning: warningIcon,
    });

    useEffect(() => {
        setList([...toastList]);

        // eslint-disable-next-line
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && toastList.length && list.length) {
                deleteToast(toastList[0].id);
            }
        }, dismissTime);

        return () => {
            clearInterval(interval);
        };
    }, [toastList, autoDelete, dismissTime, list]);

    const deleteToast = (id) => {
        const listItemIndex = list.findIndex((e) => e.id === id);
        const toastListItem = toastList.findIndex((e) => e.id === id);

        setList((itemLists) => {
            return [
                ...itemLists.slice(0, listItemIndex),
                {
                    ...itemLists[listItemIndex],
                    visible: false,
                },
                ...itemLists.slice(listItemIndex + 1),
            ];
        });

        setTimeout(() => {
            list.splice(listItemIndex, 1);
            toastList.splice(toastListItem, 1);
            setList([...list]);
        }, 500);
    };

    return (
        <>
            <div className={`notification-container ${position}`}>
                {list.map((toast, i) => (
                    <div
                        key={i}
                        className={`notification custom-toast ${position} ${
                            toast.type
                        } ${toast.visible ? "visible" : "hide"}`}
                    >
                        <button onClick={() => deleteToast(toast.id)}>
                            &times;
                        </button>
                        <div className="notification-image">
                            <img src={images[toast.type]} alt="" />
                        </div>
                        <div className="notification-texts">
                            <p className="notification-title">{toast.title}</p>
                            <p className="notification-message">
                                {toast.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

Toast.propTypes = {
    toastList: PropTypes.array.isRequired,
    position: PropTypes.string,
    autoDelete: PropTypes.bool,
    dismissTime: PropTypes.number,
};

export default Toast;
