import React, { useState, useEffect } from "react";
import useQuerys from "../../hooks/useQuerys";
import { useFormik } from "formik";

import ClienteForm from "./ClienteForm";
import Validation from "./ClienteValidation";
import { Button, Table, Modal, Pagination } from "react-bootstrap";
import { FaComment, FaPlus, FaUpload, FaUserTag } from "react-icons/fa";
import { useStore } from "../../store/StoreProvider";
import ComentariosValidation from "./ComentariosValidation";
import CargaMasivaValidation from "./CargaMasivaValidation";
import PageTitle from "../application/PageTitle";
import Comentarios from "./Comentarios";
import CargaMasiva from "./CargaMasiva";
import "./Paginados.scss";
import useFormData from "../../hooks/useFormData";

const Paginados = () => {
    const { user, theme } = useStore();
    const { setData } = useFormData();
    const { getPagination, updateItem, storeItem, getItems } = useQuerys();
    const [initialValues, setInitialValues] = useState({
        id: null,
        empresa_id: 0,
        proyecto_id: 0,
        ejecutivo_id: 0,
        nombre: "",
        apellido: "",
        rut: "",
        email: "",
        fono: "",
    });

    const [initialValuesComentarios, setInitialValuesComentarios] = useState({
        cliente_id: 0,
        proyecto_id: 0,
        user_id: user.id,
        comentarios: "",
    });

    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState({});
    const [modal, setModal] = useState(false);
    const [pagination, setPagination] = useState({});
    const [modalComentarios, setModalComentarios] = useState(false);
    const [modalCargaMasiva, setModalCargaMasiva] = useState(false);
    const [urlConsulta, setUrlConsulta] = useState("");
    const [filtro, setFiltro] = useState("");
    const [clientesFiltrados, setClientesFiltrados] = useState(false);
    const [ejecutivosClientes, setEjecutivosClientes] = useState({});
    const [ejecutivos, setEjecutivos] = useState([]);
    const [ejecutivo, setEjecutivo] = useState("all");

    useEffect(async () => {
        setEjecutivos(await getItems("/usuarios/get-all"));
        setUrlConsulta(`clientes/all/${ejecutivo}`);
    }, []);

    useEffect(async () => {
        setPagination(await getPagination(urlConsulta));
    }, [urlConsulta]);

    useEffect(() => {
        if (urlConsulta) {
            if (filtro.length >= 5) {
                setUrlConsulta(`clientes/${filtro}/${ejecutivo}`);
                setClientesFiltrados(true);
            } else if (clientesFiltrados) {
                setUrlConsulta(`clientes/all/${ejecutivo}`);
                setClientesFiltrados(false);
            } else {
                setUrlConsulta(`clientes/all/${ejecutivo}`);
            }
        }
    }, [filtro, ejecutivo]);

    useEffect(() => {
        if (typeof pagination.data != "undefined") {
            setClientes(pagination.data);
        }
    }, [pagination]);

    const Pagina = (e) => {
        e.preventDefault();
        setUrlConsulta(e.target.href);
    };

    /* const formikComentarios = useFormik({
        initialValues: initialValuesComentarios,
        validationSchema: ComentariosValidation,
        onSubmit: async (values, { resetForm }) => {
            const responseData = await storeItem(
                "/clientes/agrega-comentario",
                values,
                formikComentarios
            );

            if (responseData) {
                cliente.comentarios.unshift(responseData);
                resetForm();
            }
        },
    }); */

    const formikCargaMasiva = useFormik({
        initialValues: {
            proyecto_id: "",
            archivo: "",
        },
        validationSchema: CargaMasivaValidation,
        onSubmit: async (values, { resetForm }) => {
            const responseData = await storeItem(
                "/clientes/carga-masiva",
                setData(values)
            );
        },
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Validation,
        onSubmit: async (values, { resetForm }) => {
            let responseData = null;
            if (values.id) {
                responseData = await updateItem(
                    `clientes/${values.id}`,
                    values,
                    formik
                );
                if (responseData) {
                    setPagination(await getPagination(urlConsulta));
                    // updateData(responseData);
                }
            } else {
                responseData = await storeItem("clientes", values, formik);
                if (responseData) {
                    setClientes([...clientes, responseData]);
                }
            }

            if (responseData && !responseData.errors) {
                setModal(false);
                resetForm();
            }
        },
    });

    const setValues = (item) => {
        formik.setValues({
            id: item.id,
            empresa_id: item.empresa_id,
            proyecto_id: 0,
            ejecutivo_id: 0,
            nombre: item.nombre,
            apellido: item.apellido,
            rut: item.rut,
            email: item.email,
            fono: item.fono,
        });
    };

    return (
        <div>
            {user.role == "dios" && (
                <Button
                    className="carga-masiva"
                    variant="secondary"
                    onClick={() => {
                        setModalCargaMasiva(true);
                    }}
                >
                    <FaUpload></FaUpload>
                </Button>
            )}
            <Button
                className="plus"
                onClick={() => {
                    setModal(true);
                }}
            >
                <FaPlus></FaPlus>
            </Button>
            <PageTitle
                title="Clientes"
                withFilter={true}
                filter={filtro}
                setFilter={setFiltro}
                withFiltroEjecutivo={true}
                ejecutivos={ejecutivos}
                ejecutivo={ejecutivo}
                onChangeEjecutivo={(e) => {
                    if (typeof e.target.value === "string") {
                        setEjecutivo(e.target.value);
                    } else {
                        setEjecutivo(
                            ejecutivos.filter((p) => e.target.value == p.id)[0]
                                .id
                        );
                    }
                }}
                /* proyectos={proyectos}
                proyecto={proyecto}
                onChangeProyecto={(e) => {
                    setProyecto(
                        proyectos.filter((p) => e.target.value == p.id)[0]
                    );
                }} */
            ></PageTitle>
            <Table hover variant={theme} responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Rut</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Empresa(s)</th>
                        <th>Proyecto(s)</th>
                        <th>Ejecutivo(s)</th>
                        {/* <th>Email</th>
                        <th>Teléfono</th> */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {clientes.map((cliente) => {
                        return (
                            <tr key={cliente.id}>
                                <td>{cliente.id}</td>
                                <td>{cliente.rut}</td>
                                <td>{cliente.nombre}</td>
                                <td>{cliente.apellido}</td>
                                <td>
                                    {cliente.empresas.map((empresa) => (
                                        <div key={empresa.id}>
                                            {empresa.nombre}
                                        </div>
                                    ))}
                                </td>
                                <td>
                                    {cliente.proyectos.map((proyecto) => (
                                        <div key={proyecto.id}>
                                            {proyecto.nombre}
                                        </div>
                                    ))}
                                </td>
                                <td>
                                    {cliente.ejecutivos.map((ejecutivo) => (
                                        <div key={ejecutivo.id}>
                                            {ejecutivo.name}{" "}
                                            {ejecutivo.lastname}
                                        </div>
                                    ))}
                                </td>
                                {/*  <td>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip>{cliente.email}</Tooltip>
                                        }
                                    >
                                        <a href={`mailto:${cliente.email}`}>
                                            <FaEnvelope />
                                        </a>
                                    </OverlayTrigger>
                                </td>
                                <td>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip>{`+56${cliente.fono}`}</Tooltip>
                                        }
                                    >
                                        <a href={`tel:+56${cliente.fono}`}>
                                            <FaPhoneAlt />
                                        </a>
                                    </OverlayTrigger>
                                </td> */}
                                <td>
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            setValues(cliente);
                                            setModal(true);
                                        }}
                                        variant="secondary"
                                        title="Editar"
                                    >
                                        <FaUserTag></FaUserTag>
                                    </Button>
                                    {/* {" "}
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        title="Comentarios"
                                        onClick={() => {
                                            setCliente(cliente);
                                            setModalComentarios(true);
                                            formikComentarios.setFieldValue(
                                                "cliente_id",
                                                cliente.id
                                            );
                                            formikComentarios.setFieldValue(
                                                "proyecto_id",
                                                cliente.id
                                            );
                                        }}
                                    >
                                        <FaComment></FaComment> (
                                        {cliente.comentarios.length})
                                    </Button> */}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Pagination variant="dark">
                {typeof pagination.links != "undefined" &&
                    pagination.links.map((link, index) => {
                        return (
                            <Pagination.Item
                                href={link.url}
                                onClick={(e) => {
                                    Pagina(e);
                                }}
                                key={index}
                                active={link.label == pagination.current_page}
                            >
                                {link.label}
                            </Pagination.Item>
                        );
                    })}
            </Pagination>
            {/*  <Modal
                show={modalComentarios}
                onHide={() => {
                    setModalComentarios(false);
                    formikComentarios.setValues(initialValues);
                }}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {cliente.nombre} {cliente.apellido}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Comentarios
                        cliente={cliente}
                        formik={formikComentarios}
                    ></Comentarios>
                </Modal.Body>
            </Modal> */}

            <Modal
                show={modalCargaMasiva}
                onHide={() => {
                    setModalCargaMasiva(false);
                }}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Carga masiva de clientes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CargaMasiva formik={formikCargaMasiva} />
                </Modal.Body>
            </Modal>
            <Modal
                show={modal}
                onHide={() => {
                    setModal(false);
                    formik.setValues(initialValues);
                }}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {formik.values.id ? "Editar Cliente" : "Nuevo Cliente"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClienteForm formik={formik} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Paginados;
