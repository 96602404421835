import React, { useEffect, useState } from "react";
import { Form, FloatingLabel } from "react-bootstrap";

import "./InputFile.scss";

const InputFile = React.forwardRef(
    (
        { name, label, formik, onChange = null, preview = true },
        inputFileRef
    ) => {
        const [fileName, setFileName] = useState("");
        const [imageUrl, setImageUrl] = useState("");

        let handleChange = (e) => {
            const file = e.target.files[0];
            setFileName(file.name);
            formik.setFieldValue(name, file);
            formik.handleBlur;
            if (preview) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = (e) => {
                    setImageUrl(reader.result);
                };
            }
        };
        if (onChange) {
            handleChange = onChange;
        }

        useEffect(() => {
            if (
                formik.values[name] &&
                formik.values[name] != "null" &&
                preview
            ) {
                setImageUrl(`/storage/${formik.values[name]}`);
            }
        }, []);

        return (
            <Form.Group controlId={name} className="mb-3 position-relative">
                <FloatingLabel
                    label={label}
                    className={
                        formik.touched[name] && formik.errors[name]
                            ? "is-invalid"
                            : "is-valid"
                    }
                >
                    <Form.Control
                        type="text"
                        placeholder="Name"
                        readOnly
                        name={`name-${name}`}
                        value={fileName}
                        onClick={(e) => {
                            e.preventDefault();
                            inputFileRef.current.click();
                            return false;
                        }}
                        isInvalid={formik.touched[name] && formik.errors[name]}
                        onBlur={formik.handleBlur}
                    />
                    <input
                        className="d-none"
                        type="file"
                        name={name}
                        ref={inputFileRef}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                    />
                    {formik.touched[name] && formik.errors[name] ? (
                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors[name]}
                        </Form.Control.Feedback>
                    ) : null}
                </FloatingLabel>
                {preview && <img className="previewImage" src={imageUrl} />}
            </Form.Group>
        );
    }
);

export default InputFile;
