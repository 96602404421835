import * as Yup from "yup";

const Validation = Yup.object().shape({
    name: Yup.string()
        .min(2, "Muy Corto")
        .max(250, "Muy Largo")
        .required("Requerido"),

    lastname: Yup.string()
        .min(2, "Muy Corto")
        .max(250, "Muy Largo")
        .required("Requerido"),

    email: Yup.string()
        .email("Email no válido")
        .max(250, "Muy Largo")
        .required("Requerido"),

    phone: Yup.string()
        .matches(/^[0-9]+$/, "Sólo números")
        .min(5, "Muy Corto")
        .max(12, "Muy Largo")
        .required("Required"),

    password: Yup.string().when("id", {
        is: (id) => id == null,
        then: Yup.string()
            .required("Requerido")
            .min(8, "Muy Corto")
            .max(20, "Muy Largo"),
        otherwise: Yup.string().nullable().notRequired(),
    }),

    password_confirmation: Yup.string()
        .when("id", {
            is: (id) => id == null,
            then: Yup.string().required("Requerido"),
            otherwise: Yup.string().nullable().notRequired(),
        })
        .oneOf([Yup.ref("password")], "Contraseña no coincide."),

    empresa_id: Yup.number().required("Requerido"),

    role_id: Yup.number().required("Requerido"),

    active: Yup.boolean(),
});

export default Validation;
