import React, { useState, useEffect } from "react";
import useQuerys from "../../hooks/useQuerys";
import { useFormik } from "formik";

import EmpresaForm from "./EmpresaForm";
import Validation from "./EmpresaValidation";
import { Button, Table, Modal, Form } from "react-bootstrap";
import { FaPlus, FaPen } from "react-icons/fa";
import { useStore } from "../../store/StoreProvider";

const Empresas = () => {
    const { user, theme } = useStore();
    const { getItems, updateItem, storeItem } = useQuerys();
    const [initialValues, setInitialValues] = useState({
        id: null,
        nombre: "",
        logo: "",
        activo: true,
    });

    const [empresas, setEmpresas] = useState([]);
    const [modal, setModal] = useState(false);

    useEffect(async () => {
        setEmpresas(await getItems("empresas/get-all"));
    }, []);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Validation,
        onSubmit: async (values) => {
            const formData = new FormData();

            formData.append("nombre", values.nombre);
            formData.append("activo", values.activo);
            if (typeof values.logo == "object") {
                formData.append("logo", values.logo);
            }

            let responseData = null;
            if (values.id) {
                responseData = await updateItem(
                    `empresas/${values.id}`,
                    formData,
                    formik
                );
                if (responseData) {
                    updateData(responseData);
                }
            } else {
                responseData = await storeItem("empresas", formData, formik);
                if (responseData) {
                    if (responseData) {
                        setEmpresas([...empresas, responseData]);
                    }
                }
            }

            if (responseData && !responseData.errors) {
                setModal(false);
            }
        },
    });

    const setValues = (item) => {
        formik.setValues({
            id: item.id,
            nombre: item.nombre,
            logo: item.logo,
            activo: item.activo,
        });
    };

    const updateData = async (itemNewData) => {
        if (typeof itemNewData != "undefined") {
            const itemIndex = empresas.findIndex(
                (p) => p.id === itemNewData.id
            );

            setEmpresas((empresas) => {
                return [
                    ...empresas.slice(0, itemIndex),
                    itemNewData,
                    ...empresas.slice(itemIndex + 1),
                ];
            });
        } else {
            setEmpresas(await getItems("empresas/get-all"));
        }
    };

    const toggleActive = async (item) => {
        item.activo = !item.activo;

        const responseData = await updateItem(
            `empresas/${item.id}`,
            item,
            formik
        );

        updateData(responseData);
    };

    return (
        <div>
            <Button
                className="plus"
                onClick={() => {
                    setModal(true);
                }}
            >
                <FaPlus></FaPlus>
            </Button>
            <h2 className="page-title">Empresas</h2>
            <Table hover variant={theme} responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Activo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {empresas.map((empresa) => {
                        return (
                            <tr key={empresa.id}>
                                <td>{empresa.id}</td>
                                <td>{empresa.nombre}</td>
                                <td>
                                    <Form.Check
                                        type="switch"
                                        checked={empresa.activo}
                                        onChange={() => {
                                            toggleActive(empresa);
                                        }}
                                    ></Form.Check>
                                </td>
                                <td>
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            setValues(empresa);
                                            setModal(true);
                                        }}
                                    >
                                        <FaPen></FaPen>
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Modal
                show={modal}
                onHide={() => {
                    setModal(false);
                    formik.setValues(initialValues);
                }}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {formik.values.id ? "Editar Empresa" : "Nueva Empresa"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EmpresaForm formik={formik} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Empresas;
