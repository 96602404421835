import React, { useState } from "react";
import useQuerys from "../../hooks/useQuerys";
import { useFormik } from "formik";
import { Table, Form } from "react-bootstrap";
import Input from "../util/form/Input";
import Select from "../util/form/Select";
import "./Unidades.scss";
import { FaSave, FaUndoAlt } from "react-icons/fa";

const Unidades = ({ refreshProyectos, setModal, proyecto }) => {
    const { storeItem } = useQuerys();

    const [unidades, setUnidades] = useState(proyecto.unidades);
    const [edificios, setEdificios] = useState(proyecto.edificios);
    const [modelos, setModelos] = useState(proyecto.plantas);
    const [orientaciones, setOrientaciones] = useState(proyecto.orientaciones);
    const [terrenos, setTerrenos] = useState(proyecto.terrenos);
    const [estados, setEstados] = useState([
        { id: 1, nombre: "Disponible" },
        { id: 2, nombre: "No Disponible" },
    ]);

    const defaultValues = {};
    proyecto.unidades.map((unidad) => {
        defaultValues[`${unidad.id}|edificio_id`] = unidad.edificio_id;
        defaultValues[`${unidad.id}|nombre`] = unidad.nombre;
        defaultValues[`${unidad.id}|planta_id`] = unidad.planta_id;
        defaultValues[`${unidad.id}|piso`] = unidad.piso;
        defaultValues[`${unidad.id}|orientacion_id`] = unidad.orientacion_id;
        defaultValues[`${unidad.id}|terreno_id`] = unidad.terreno_id;
        defaultValues[`${unidad.id}|estado_id`] = unidad.estado_id;
        defaultValues[`${unidad.id}|valor`] = unidad.valor;
    });

    const formik = useFormik({
        initialValues: defaultValues,
        onSubmit: async (values) => {
            let data = {};
            proyecto.unidades.map((unidad) => {
                if (values[`${unidad.id}|edificio_id`] != unidad.edificio_id) {
                    data[`${unidad.id}|edificio_id`] =
                        values[`${unidad.id}|edificio_id`];
                }
                if (values[`${unidad.id}|nombre`] != unidad.nombre) {
                    data[`${unidad.id}|nombre`] = values[`${unidad.id}|nombre`];
                }
                if (values[`${unidad.id}|planta_id`] != unidad.planta_id) {
                    data[`${unidad.id}|planta_id`] =
                        values[`${unidad.id}|planta_id`];
                }
                if (values[`${unidad.id}|piso`] != unidad.piso) {
                    data[`${unidad.id}|piso`] = values[`${unidad.id}|piso`];
                }
                if (
                    values[`${unidad.id}|orientacion_id`] !=
                    unidad.orientacion_id
                ) {
                    data[`${unidad.id}|orientacion_id`] =
                        values[`${unidad.id}|orientacion_id`];
                }
                if (values[`${unidad.id}|terreno_id`] != unidad.terreno_id) {
                    data[`${unidad.id}|terreno_id`] =
                        values[`${unidad.id}|terreno_id`];
                }
                if (values[`${unidad.id}|estado_id`] != unidad.estado_id) {
                    data[`${unidad.id}|estado_id`] =
                        values[`${unidad.id}|estado_id`];
                }
                if (values[`${unidad.id}|valor`] != unidad.valor) {
                    data[`${unidad.id}|valor`] = values[`${unidad.id}|valor`];
                }
            });

            console.log("data", data);

            let responseData = await storeItem(
                "proyectos/unidades/actualizacion-masiva",
                data,
                formik
            );
            if (responseData) {
                refreshProyectos();
                setModal(false);
            }
        },
    });

    const cierraModal = () => {
        if (!formik.dirty) {
            setModal(false);
            return false;
        }
        if (
            confirm(
                "Tienes cambios sin guardar. ¿Quieres descartar estos cambios?"
            )
        ) {
            setModal(false);
        }
    };

    return (
        <>
            <Form id="unidades" onSubmit={formik.handleSubmit}>
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            {proyecto.tipo_id == 1 && <th>Edificio</th>}
                            <th>Nombre</th>
                            <th>Modelo</th>
                            {proyecto.tipo_id == 1 && <th>Orientación</th>}
                            {proyecto.tipo_id == 1 && <th>Piso</th>}
                            {proyecto.tipo_id == 2 && <th>Terreno</th>}
                            <th>Estado</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {unidades.map((unidad) => {
                            return (
                                <tr key={unidad.id}>
                                    {proyecto.tipo_id == 1 && (
                                        <td>
                                            <Select
                                                name={`${unidad.id}|edificio_id`}
                                                label="Edificio"
                                                options={edificios}
                                                optionKey="id"
                                                optionValue="id"
                                                optionText="nombre"
                                                formik={formik}
                                            ></Select>
                                        </td>
                                    )}
                                    <td>
                                        <Input
                                            name={`${unidad.id}|nombre`}
                                            label="Nombre"
                                            formik={formik}
                                        ></Input>
                                    </td>
                                    <td>
                                        <Select
                                            name={`${unidad.id}|modelo_id`}
                                            label="Modelo"
                                            options={modelos}
                                            optionKey="id"
                                            optionValue="id"
                                            optionText="nombre"
                                            formik={formik}
                                        ></Select>
                                    </td>
                                    {proyecto.tipo_id == 1 && (
                                        <td>
                                            <Select
                                                name={`${unidad.id}|orientacion_id`}
                                                label="Orientación"
                                                options={orientaciones}
                                                optionKey="id"
                                                optionValue="id"
                                                optionText="nombre"
                                                formik={formik}
                                            ></Select>
                                        </td>
                                    )}
                                    {proyecto.tipo_id == 1 && (
                                        <td>
                                            <Input
                                                name={`${unidad.id}|piso`}
                                                label="Piso"
                                                formik={formik}
                                            ></Input>
                                        </td>
                                    )}
                                    {proyecto.tipo_id == 2 && (
                                        <td>
                                            <Select
                                                name={`${unidad.id}|terreno_id`}
                                                label="Terreno"
                                                options={terrenos}
                                                optionKey="id"
                                                optionValue="id"
                                                optionText="nombre"
                                                formik={formik}
                                            ></Select>
                                        </td>
                                    )}
                                    <td>
                                        <Select
                                            name={`${unidad.id}|estado_id`}
                                            label="Esado"
                                            options={estados}
                                            optionKey="id"
                                            optionValue="id"
                                            optionText="nombre"
                                            formik={formik}
                                        ></Select>
                                    </td>
                                    <td>
                                        <Input
                                            name={`${unidad.id}|valor`}
                                            label="Valor"
                                            formik={formik}
                                        ></Input>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <div className="row">
                    <div className="col-6 text-end">
                        <button
                            className=" btn btn-lg btn-primary btn-guardar mt-4 "
                            type="submit"
                            disabled={!formik.dirty}
                        >
                            Guardar
                            <FaSave></FaSave>
                        </button>
                    </div>
                    <div className="col-6">
                        <button
                            className=" btn btn-lg btn-secondary mt-4 btn-cancelar "
                            onClick={(e) => {
                                e.preventDefault();
                                cierraModal();
                            }}
                        >
                            Cancelar
                            <FaUndoAlt></FaUndoAlt>
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default Unidades;
