import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams, Navigate } from "react-router-dom";
import Input from "../util/form/Input";
import useAuth from "../../hooks/useAuth";

const ResetPassword = () => {
    const { token } = useParams();
    const { resetPassword } = useAuth();
    const email = useRef(null);
    const [toLogin, setToLogin] = useState(false);

    const goToLogin = () => {
        console.log("entró");
        setToLogin(true);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            password_confirmation: "",
        },
        onSubmit: (values) => {
            resetPassword(values, goToLogin);
        },
    });

    useEffect(() => {
        formik.setValues({ token: token });
    }, []);

    return toLogin ? (
        <Navigate to={"/"} />
    ) : (
        <div className="form-signin">
            <Form noValidate onSubmit={formik.handleSubmit}>
                <h1 className="h3 mb-3 fw-normal">Restablecer Contraseña</h1>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <Input
                            name="token"
                            label="Token"
                            type="hidden"
                            formik={formik}
                        />
                    </div>
                </div>
                <Input
                    name="email"
                    label="Email"
                    type="email"
                    formik={formik}
                    ref={email}
                />

                <Input
                    name="password"
                    label="Contraseña"
                    type="password"
                    formik={formik}
                />

                <Input
                    name="password_confirmation"
                    label="Repite Contraseña"
                    type="password"
                    formik={formik}
                />
                <button className="w-100 btn btn-lg btn-primary" type="submit">
                    Restablecer Contraseña
                </button>
            </Form>
        </div>
    );
};

export default ResetPassword;
