import React, { useEffect } from "react";
import { useDispatch } from "../../store/StoreProvider";
import { types } from "../../store/storeReducer";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import Input from "../util/form/Input";
import * as Yup from "yup";
import "./CambiaPass.scss";
import useQuerys from "../../hooks/useQuerys";
import useAuth from "../../hooks/useAuth";

const Validation = Yup.object().shape({
    password: Yup.string()
        .required("Requerido")
        .min(8, "Muy Corto")
        .max(25, "Muy Largo"),

    password_confirmation: Yup.string()
        .required("Requerido")
        .oneOf([Yup.ref("password")], "Contraseña no coincide."),
});

const CambiaPass = () => {
    const dispatch = useDispatch();
    const { storeItem } = useQuerys();
    const { logOut } = useAuth();

    useEffect(() => {
        dispatch({
            type: types.messageShow,
            payload: {
                type: "warning",
                message: "Es necesario cambiar tu contraseña",
            },
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            password: "",
            password_confirmation: "",
        },
        validationSchema: Validation,
        onSubmit: async (values) => {
            responseData = await storeItem(
                "usuarios/cambia-clave",
                values,
                formik,
                () => {
                    logOut();
                }
            );
        },
    });

    return (
        <div id="cambia-pass">
            <h2 className="page-title">Cambio de Contraseña</h2>
            <div className="row">
                <div className="mt-3 col-md-6 offset-md-3">
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Input
                            name="password"
                            label="Nueva Contraseña"
                            type="password"
                            formik={formik}
                        />
                        <Input
                            name="password_confirmation"
                            label="Repite Contraseña"
                            type="password"
                            formik={formik}
                        />
                        <button
                            className="w-100 btn btn-lg btn-primary"
                            type="submit"
                        >
                            Cambiar Contraseña
                        </button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default CambiaPass;
