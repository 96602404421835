import React, { useEffect, useState } from "react";
import useQuerys from "../../hooks/useQuerys";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PageTitle from "../application/PageTitle";
import ClienteForm from "./ClienteForm";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import "./Paneles.scss";
import {
    FaComment,
    FaEnvelope,
    FaPencilAlt,
    FaPhone,
    FaPlus,
} from "react-icons/fa";
import Comentarios from "./Comentarios";
import { useFormik } from "formik";
import ComentariosValidation from "./ComentariosValidation";
import ClienteValidation from "./ClienteValidation";
import { useStore } from "../../store/StoreProvider";

const Paneles = () => {
    const { user } = useStore();
    const { getItems, updateItem, storeItem } = useQuerys();

    const [filtro, setFiltro] = useState("");
    const [cliente, setCliente] = useState({});
    const [proyectos, setProyectos] = useState([]);
    const [etapas, setEtapas] = useState([]);
    const [columnas, setColumnas] = useState([]);
    const [proyecto, setProyecto] = useState({});
    const [modalComentarios, setModalComentarios] = useState(false);
    const [modalCliente, setModalCliente] = useState(false);
    const [primeraEntrada, setPrimeraEntrada] = useState(true);
    const [clientesFiltrados, setClientesFiltrados] = useState(false);

    const [initialValuesCliente, setInitialValuesCliente] = useState({
        id: null,
        empresa_id: 0,
        proyecto_id: 0,
        ejecutivo_id: 0,
        nombre: "",
        apellido: "",
        rut: "",
        email: "",
        fono: "",
    });

    const [initialValuesComentarios, setInitialValuesComentarios] = useState({
        cliente_id: 0,
        proyecto_id: 0,
        user_id: user.id,
        comentarios: "",
    });

    const formikComentarios = useFormik({
        initialValues: initialValuesComentarios,
        validationSchema: ComentariosValidation,
        onSubmit: async (values, { resetForm }) => {
            const responseData = await storeItem(
                "/clientes/agrega-comentario",
                values,
                formikComentarios
            );

            if (responseData) {
                cliente.comentarios.unshift(responseData);
                resetForm();
            }
        },
    });

    const formikCliente = useFormik({
        initialValues: initialValuesCliente,
        validationSchema: ClienteValidation,
        onSubmit: async (values, { resetForm }) => {
            let responseData = null;
            if (values.id) {
                responseData = await updateItem(
                    `clientes/${values.id}`,
                    values,
                    formikCliente
                );
                /* if (responseData) {
                    getProyectos(true);
                    resetForm();
                } */
            } else {
                responseData = await storeItem(
                    "clientes",
                    values,
                    formikCliente
                );
                /* if (responseData) {
                    setProyectos([...proyectos, responseData]);
                } */
            }

            if (responseData && !responseData.errors) {
                resetForm();
                setModalCliente(false);
                getProyectos(true);
            }
        },
    });

    const setValuesCliente = (item) => {
        formikCliente.setValues({
            id: item.id,
            empresa_id: item.empresa_id,
            proyecto_id: 0,
            ejecutivo_id: 0,
            nombre: item.nombre,
            apellido: item.apellido,
            rut: item.rut,
            email: item.email,
            fono: item.fono,
        });
    };

    useEffect(async () => {
        getProyectos();
    }, [filtro]);

    useEffect(async () => {
        setEtapas(await getItems("etapas-clientes"));
    }, []);

    useEffect(async () => {
        if (etapas.length) {
            setProyectos(await getItems("/proyectos/get-all-with-clients"));
        }
    }, [etapas]);

    useEffect(() => {
        if (proyectos.length) {
            if (primeraEntrada) {
                setProyecto(proyectos[0]);
                setPrimeraEntrada(false);
            } else {
                const idProyectoSeleccionado = proyecto.id;
                const proyectoSeleccionado = proyectos.filter(
                    (p) => p.id == idProyectoSeleccionado
                );

                if (proyectoSeleccionado.length) {
                    setProyecto(proyectoSeleccionado[0]);
                }
            }
        }
    }, [proyectos]);

    useEffect(() => {
        ordenaColumnas();
    }, [proyecto]);

    const getProyectos = async (afterUpadate = false) => {
        if (afterUpadate) {
            if (filtro.length >= 5) {
                setProyectos(
                    await getItems(`/proyectos/get-all-with-clients/${filtro}`)
                );
                setClientesFiltrados(true);
            } else {
                setProyectos(await getItems("/proyectos/get-all-with-clients"));
                setClientesFiltrados(false);
            }
        } else {
            if (filtro.length >= 5) {
                setProyectos(
                    await getItems(`/proyectos/get-all-with-clients/${filtro}`)
                );
                setClientesFiltrados(true);
            } else if (clientesFiltrados) {
                setProyectos(await getItems("/proyectos/get-all-with-clients"));
                setClientesFiltrados(false);
            }
        }
    };

    const ordenaColumnas = () => {
        const columnasOrdenadas = [];
        etapas.map((etapa) => {
            const estaEtapa = { id: etapa.id, title: etapa.nombre, items: [] };
            estaEtapa.items = proyecto.clientes.filter((cliente) => {
                if (cliente.etapa.length) {
                    return cliente.etapa[0].id == etapa.id;
                }
                return false;
            });

            columnasOrdenadas.push(estaEtapa);
        });

        setColumnas(columnasOrdenadas);
    };

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (
            !destination /* || +source.droppableId == +destination.droppableId */
        ) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(
                columnas[sInd],
                source.index,
                destination.index
            );
            const newColumnas = [...columnas];
            newColumnas[sInd].items = items;
            setColumnas(newColumnas);
        } else {
            const result = move(
                columnas[sInd],
                columnas[dInd],
                source,
                destination
            );
            const newColumnas = [...columnas];
            newColumnas[sInd].items = result[sInd];
            newColumnas[dInd].items = result[dInd];

            const etapa = newColumnas[dInd];
            const cliente = etapa.items[destination.index];

            updateItem("/clientes/actualiza-etapa", {
                cliente: cliente.id,
                etapa: etapa.id,
                proyecto: proyecto.id,
            });

            setColumnas(newColumnas);
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list.items);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const move = (
        source,
        destination,
        droppableSource,
        droppableDestination
    ) => {
        const sourceClone = Array.from(source.items);
        const destClone = Array.from(destination.items);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        position: "relative",
        cursor: "move",
        color: "var(--client-card-color)",
        // change background colour if dragging
        background: isDragging
            ? "var(--client-card-dragging-background)"
            : "var(--client-card-background)",

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: 250,
        height: "100%",
        overflow: "auto",
    });

    return (
        <>
            <Button
                className="plus"
                onClick={() => {
                    setModalCliente(true);
                    formikCliente.setFieldValue("proyecto_id", proyecto.id);
                    formikCliente.setFieldValue(
                        "empresa_id",
                        proyecto.empresa_id
                    );
                }}
            >
                <FaPlus></FaPlus>
            </Button>

            <PageTitle
                title="Clientes"
                withFilter={true}
                filter={filtro}
                setFilter={setFiltro}
                withFiltroProyecto={true}
                proyectos={proyectos}
                proyecto={proyecto}
                onChangeProyecto={(e) => {
                    setProyecto(
                        proyectos.filter((p) => e.target.value == p.id)[0]
                    );
                }}
            ></PageTitle>

            <div className="columnas-clientes" style={{ display: "flex" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    {columnas.map((columna, ind) => (
                        <div key={`col-${ind}`} className="contine-columna">
                            <h5>{columna.title}</h5>
                            <Droppable droppableId={`${ind}`}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(
                                            snapshot.isDraggingOver
                                        )}
                                        {...provided.droppableProps}
                                    >
                                        {columna.items.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={`${item.id}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className="client-card"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided
                                                                .draggableProps
                                                                .style
                                                        )}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-around",
                                                            }}
                                                        >
                                                            <div className="tarjeta-cliente">
                                                                <Button
                                                                    className="edita-cliente"
                                                                    variant="info"
                                                                    size="sm"
                                                                    title="Editar Cliente"
                                                                    onClick={() => {
                                                                        setValuesCliente(
                                                                            item
                                                                        );
                                                                        setModalCliente(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <FaPencilAlt></FaPencilAlt>
                                                                </Button>
                                                                <strong>
                                                                    {item.nombre.toLowerCase()}{" "}
                                                                    {item.apellido.toLowerCase()}
                                                                </strong>
                                                                <div className="rut">
                                                                    {item.rut}
                                                                </div>
                                                                <div className="botones">
                                                                    <Button
                                                                        size="sm"
                                                                        variant="secondary"
                                                                        title={
                                                                            item.fono
                                                                        }
                                                                        target="_blank"
                                                                        href={`tel:${item.fono}`}
                                                                    >
                                                                        <FaPhone></FaPhone>
                                                                    </Button>{" "}
                                                                    <Button
                                                                        size="sm"
                                                                        variant="secondary"
                                                                        title={
                                                                            item.email
                                                                        }
                                                                        target="_blank"
                                                                        href={`mailto:${item.email}`}
                                                                    >
                                                                        <FaEnvelope></FaEnvelope>
                                                                    </Button>
                                                                    <Button
                                                                        size="sm"
                                                                        variant="secondary"
                                                                        title="Comentarios"
                                                                        onClick={() => {
                                                                            setCliente(
                                                                                item
                                                                            );
                                                                            setModalComentarios(
                                                                                true
                                                                            );
                                                                            formikComentarios.setFieldValue(
                                                                                "cliente_id",
                                                                                item.id
                                                                            );
                                                                            formikComentarios.setFieldValue(
                                                                                "proyecto_id",
                                                                                proyecto.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <FaComment></FaComment>{" "}
                                                                        (
                                                                        {
                                                                            item
                                                                                .comentarios
                                                                                .length
                                                                        }
                                                                        )
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    ))}
                </DragDropContext>
            </div>

            <Modal
                show={modalComentarios}
                onHide={() => {
                    setModalComentarios(false);
                    formikComentarios.setValues(initialValues);
                }}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {cliente.nombre} {cliente.apellido}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Comentarios
                        cliente={cliente}
                        formik={formikComentarios}
                    ></Comentarios>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalCliente}
                onHide={() => {
                    setModalCliente(false);
                    formikCliente.setValues(initialValues);
                }}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {formikCliente.values.id
                            ? "Editar Cliente"
                            : "Nuevo Cliente"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClienteForm formik={formikCliente} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Paneles;
