import axios from "axios";
import Cookies from "js-cookie";

import useCrypt from "../hooks/useCrypt";
import useStorage from "../hooks/useStorage";

const { uncrypt } = useCrypt();
const { getToken } = useStorage();
const Axios = axios.create({
    baseURL: `/api/`,
    // headers: { "X-Requested-With": "XMLHttpRequest" },
    withCredentials: true,
});
// Axios.defaults.headers.common[] = "XMLHttpRequest";

let token = getToken();
if (token) {
    token = uncrypt(token);
    Axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
        "X-Requested-With": "XMLHttpRequest",
    };
}

export default Axios;
