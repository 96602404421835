const useFormData = () => {
    const setData = (values, images = []) => {
        const formData = new FormData();
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                const value = values[key];
                if (images.includes(key)) {
                    if (typeof value == "object") {
                        formData.append(key, value);
                    }
                } else {
                    if (value) {
                        formData.append(key, value);
                    }
                }
            }
        }
        return formData;
    };
    return { setData };
};

export default useFormData;
