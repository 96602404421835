import * as Yup from "yup";

const Validation = Yup.object().shape({
    nombre: Yup.string()
        .min(2, "Muy Corto")
        .max(250, "Muy Largo")
        .required("Requerido"),

    activo: Yup.boolean(),
});

export default Validation;
