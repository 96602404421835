import Axios from "../config/Axios";
import Cookies from "js-cookie";
import useStorage from "./useStorage";

import { useDispatch } from "../store/StoreProvider";
import { types } from "../store/storeReducer";
import useCrypt from "./useCrypt";

const useAuth = () => {
    const dispatch = useDispatch();
    const { crypt } = useCrypt();

    const { setToken, getToken, deleteToken, setUser, getUser, deleteUser } =
        useStorage();
    // deleteToken();
    // deleteUser();
    const logIn = (email, password) => {
        dispatch({
            type: types.loadingShow,
        });

        const values = {
            email,
            password,
        };

        Axios.get("client");

        Axios.post("auth/login", values)
            .then((response) => {
                const in30Minutes = 1 / 48;
                setUser(crypt(JSON.stringify(response.data.data.user)));
                setToken(crypt(response.data.data.token));
                // Cookies.set(
                //     "user",
                //     crypt(JSON.stringify(response.data.data.user)),
                //     {
                //         secure: true,
                //         expires: in30Minutes,
                //     }
                // );
                // Cookies.set("token", crypt(response.data.data.token), {
                //     secure: true,
                //     expires: in30Minutes,
                // });
                Axios.defaults.headers.common = {
                    Authorization: `Bearer ${response.data.data.token}`,
                };
                dispatch({
                    type: types.authLogin,
                    payload: {
                        token: response.data.data.token,
                        user: response.data.data.user,
                    },
                });
                dispatch({
                    type: types.loadingHide,
                });
            })
            .catch((error) => {
                const message = error.response.data.message;
                dispatch({
                    type: types.loadingHide,
                });
                dispatch({
                    type: types.messageShow,
                    payload: { type: "danger", message: message },
                });
            });
    };

    const logOut = () => {
        dispatch({
            type: types.loadingShow,
        });
        Axios.post("auth/logout").then((response) => {
            deleteToken();
            deleteUser();
            Cookies.remove("laravel_session");
            Cookies.remove("XSRF-TOKEN");
            dispatch({ type: types.authLogout });
            dispatch({
                type: types.loadingHide,
            });
            console.log("chao");
        });
    };

    const forgotPassword = (email) => {
        dispatch({
            type: types.loadingShow,
        });

        const values = {
            email,
        };

        Axios.post("forgot-my-password", values)
            .then((response) => {
                dispatch({
                    type: types.messageShow,
                    payload: {
                        type: "success",
                        message: response.data.message,
                    },
                });
            })
            .catch((error) => {
                const message = error.response.data.message;
                dispatch({
                    type: types.messageShow,
                    payload: { type: "danger", message: message },
                });
            })
            .finally(() => {
                dispatch({
                    type: types.loadingHide,
                });
            });
    };

    const resetPassword = (values, callBack) => {
        dispatch({
            type: types.loadingShow,
        });

        Axios.post("reset-password", values)
            .then((response) => {
                dispatch({
                    type: types.messageShow,
                    payload: {
                        type: "success",
                        message: response.data.message,
                    },
                });
                callBack();
            })
            .catch((error) => {
                const message = error.response.data.message;
                dispatch({
                    type: types.messageShow,
                    payload: { type: "danger", message: message },
                });
            })
            .finally(() => {
                dispatch({
                    type: types.loadingHide,
                });
            });
    };

    return { logIn, logOut, forgotPassword, resetPassword };
};

export default useAuth;
