import React from "react";
import { Form } from "react-bootstrap";

import "./CheckboxGroup.scss";

const CheckboxGroup = ({ name, label, items, formik, onChange = null }) => {
    return (
        <div className="checkbox-group">
            <h6>{label}</h6>
            {items.map((item) => {
                let checked = false;
                if (formik.values[name]) {
                    checked = formik.values[name].find((item_id) => {
                        if (typeof item_id == "number") {
                            return item.id == item_id;
                        }
                        return item.id == item_id.id;
                    });
                }

                return (
                    <Form.Group
                        controlId={`${name}-${item.id}`}
                        className="mb-3 position-relative"
                        key={`${name}-${item.id}`}
                    >
                        <Form.Check
                            inline
                            label={item.nombre}
                            name={item.name}
                            type="checkbox"
                            value={item.id}
                            checked={checked}
                            onChange={(e) => {
                                let items_helper = [];
                                if (formik.values[name]) {
                                    items_helper = formik.values[name];
                                }
                                if (e.target.checked) {
                                    items_helper.push(e.target.value);
                                    formik.setFieldValue(name, items_helper);
                                } else {
                                    formik.setFieldValue(
                                        name,
                                        items_helper.filter((item_helper) => {
                                            return (
                                                parseInt(item_helper) !==
                                                parseInt(e.target.value)
                                            );
                                        })
                                    );
                                }

                                if (onChange) {
                                    const checked = {};
                                    checked[e.target.value] = e.target.checked;
                                    onChange(checked);
                                }
                            }}
                        />
                    </Form.Group>
                );
            })}
            {formik.touched[name] &&
            !formik.touched[name].length &&
            formik.errors[name] ? (
                <Form.Control.Feedback type="invalid" tooltip>
                    {formik.errors[name]}
                </Form.Control.Feedback>
            ) : null}
        </div>
    );
};

export default CheckboxGroup;
