import React, { useState, useEffect } from "react";
import useQuerys from "../../hooks/useQuerys";
import { useFormik } from "formik";

import ProyectoForm from "./ProyectoForm";
import Validation from "./ProyectoValidation";
import { Button, Table, Modal, Form } from "react-bootstrap";
import { FaPlus, FaPen, FaDatabase, FaDownload, FaEye } from "react-icons/fa";
import { RiMailDownloadFill } from "react-icons/ri";
import { useStore } from "../../store/StoreProvider";
import SolicitudReporte from "../reportes/SolicitudReporte";
import Unidades from "../unidades/Unidades";
import formulariosUrl from "../../config/formulariosUrl";

const Proyectos = () => {
    const { user, theme } = useStore();
    const { getItems, updateItem, storeItem } = useQuerys();
    const [initialValues, setInitialValues] = useState({
        id: null,
        empresa_id: 0,
        region_id: 0,
        comuna_id: 0,
        tipo_id: 0,
        supervisor_id: 0,
        ejecutivos: [],
        nombre: "",
        logo: "",
        imagen: "",
        email: "",
        direccion: "",
        url: "",
        entrega: "",
        valor_reserva: "",
        activo: true,
    });

    const [proyectos, setProyectos] = useState([]);
    const [proyecto, setProyecto] = useState({});
    const [modal, setModal] = useState(false);
    const [modalAdvertencia, setModalAdvertencia] = useState(false);
    const [respuestaCargaMasiva, setRespuestaCargaMasiva] = useState(null);
    const [modalReporte, setModalReporte] = useState(false);
    const [modalReporteFormulario, setModalReporteFormulario] = useState(false);
    const [modalUnidades, setModalUnidades] = useState(false);

    useEffect(async () => {
        setProyectos(await getItems("proyectos/get-all"));
    }, []);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Validation,
        onSubmit: async (values) => {
            const formData = new FormData();
            for (const key in values) {
                if (Object.hasOwnProperty.call(values, key)) {
                    const value = values[key];
                    const images = ["logo", "imagen"];
                    if (images.includes(key)) {
                        if (typeof value == "object") {
                            formData.append(key, value);
                        }
                    } else {
                        if (value) {
                            formData.append(key, value);
                        }
                    }
                }
            }

            let responseData = null;
            if (values.id) {
                responseData = await updateItem(
                    `proyectos/${values.id}`,
                    formData,
                    formik
                );
                if (responseData) {
                    updateData(responseData);
                }
            } else {
                responseData = await storeItem("proyectos", formData, formik);
                if (responseData) {
                    setProyectos([...proyectos, responseData]);
                }
            }

            if (responseData && !responseData.errors) {
                setModal(false);
            }
        },
    });

    const setValues = (item) => {
        let ejecutivos = [];
        if (item.ejecutivos.length) {
            item.ejecutivos.map((ejecutivo) => {
                ejecutivos.push(ejecutivo.id);
            });
        }

        formik.setValues({
            id: item.id,
            empresa_id: item.empresa_id,
            region_id: item.region_id,
            comuna_id: item.comuna_id,
            tipo_id: item.tipo_id,
            supervisor_id: item.supervisor_id,
            ejecutivos: ejecutivos,
            nombre: item.nombre,
            email: item.email,
            direccion: item.direccion,
            url: item.url,
            activo: item.activo,
            logo: item.logo,
            imagen: item.imagen,
            valor_reserva: item.valor_reserva,
            entrega: item.entrega,
        });
    };

    const updateData = async (itemNewData) => {
        if (typeof itemNewData != "undefined") {
            const itemIndex = proyectos.findIndex(
                (p) => p.id === itemNewData.id
            );

            setProyectos((proyectos) => {
                return [
                    ...proyectos.slice(0, itemIndex),
                    itemNewData,
                    ...proyectos.slice(itemIndex + 1),
                ];
            });
        } else {
            setProyectos(await getItems("proyectos/get-all"));
        }
    };

    const toggleActive = async (item) => {
        item.activo = !item.activo;

        const responseData = await updateItem(
            `proyectos/${item.id}`,
            item,
            formik
        );

        updateData(responseData);
    };

    const cargaMasiva = async (proyecto_id) => {
        setRespuestaCargaMasiva(
            await getItems(`proyecto/carga/${proyecto_id}`)
        );
    };

    return (
        <div>
            {user.role == "dios" && (
                <Button
                    className="plus"
                    onClick={() => {
                        setModal(true);
                    }}
                >
                    <FaPlus></FaPlus>
                </Button>
            )}
            <h2 className="page-title">Proyectos</h2>
            <Table hover variant={theme} responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Tipo</th>
                        <th>Url</th>
                        <th>Activo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {proyectos.map((proyecto) => {
                        return (
                            <tr key={proyecto.id}>
                                <td>{proyecto.id}</td>
                                <td>{proyecto.nombre}</td>
                                <td>{proyecto.tipo.nombre}</td>
                                <td>
                                    <a
                                        href={`/cotizacion/${proyecto.slug}`}
                                        target="_blank"
                                    >
                                        Ver Formulario
                                    </a>
                                </td>
                                <td>
                                    {user.role == "dios" ? (
                                        <Form.Check
                                            type="switch"
                                            checked={proyecto.activo}
                                            onChange={() => {
                                                toggleActive(proyecto);
                                            }}
                                        ></Form.Check>
                                    ) : proyecto.activo ? (
                                        "Si"
                                    ) : (
                                        "No"
                                    )}
                                </td>
                                <td>
                                    {(user.role == "dios" ||
                                        user.role == "supervisor") && (
                                        <Button
                                            size="sm"
                                            onClick={() => {
                                                setValues(proyecto);
                                                setModal(true);
                                            }}
                                            variant="secondary"
                                            title="Editar"
                                        >
                                            <FaPen></FaPen>
                                        </Button>
                                    )}{" "}
                                    {user.role == "dios" && (
                                        <Button
                                            size="sm"
                                            onClick={() => {
                                                setProyecto(proyecto);
                                                setModalAdvertencia(true);
                                            }}
                                            variant="secondary"
                                            title="Carga Masiva"
                                        >
                                            <FaDatabase></FaDatabase>
                                        </Button>
                                    )}{" "}
                                    <Button
                                        onClick={() => {
                                            setProyecto(proyecto);
                                            setModalUnidades(true);
                                        }}
                                        size="sm"
                                        variant="secondary"
                                        title="Ver Unidades"
                                    >
                                        <FaEye></FaEye>
                                    </Button>{" "}
                                    <Button
                                        onClick={() => {
                                            setProyecto(proyecto);
                                            setModalReporte(true);
                                        }}
                                        size="sm"
                                        variant="secondary"
                                        title="Reporte Cotizaciones"
                                    >
                                        <FaDownload></FaDownload>
                                    </Button>{" "}
                                    <Button
                                        onClick={() => {
                                            setProyecto(proyecto);
                                            setModalReporteFormulario(true);
                                        }}
                                        size="sm"
                                        variant="secondary"
                                        title="Reporte Formularios"
                                    >
                                        <RiMailDownloadFill></RiMailDownloadFill>
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Modal
                show={modal}
                onHide={() => {
                    setModal(false);
                    formik.setValues(initialValues);
                }}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {formik.values.id
                            ? "Editar Proyecto"
                            : "Nuevo Proyecto"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProyectoForm formik={formik} />
                </Modal.Body>
            </Modal>

            <Modal
                show={modalUnidades}
                onHide={() => {
                    setModalUnidades(false);
                }}
                fullscreen={true}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{`Unidades ${proyecto.nombre}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Unidades
                        refreshProyectos={async () => {
                            setProyectos(await getItems("proyectos/get-all"));
                        }}
                        setModal={setModalUnidades}
                        proyecto={proyecto}
                    ></Unidades>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalAdvertencia}
                onHide={() => {
                    setModalAdvertencia(false);
                    setRespuestaCargaMasiva(null);
                }}
                size="lg"
                backdrop="static"
                dialogClassName="modal-advertencia"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Prepara el contenido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {respuestaCargaMasiva ? (
                        <table className="table small table-hover">
                            <tbody>
                                <tr>
                                    <th>Plantas</th>
                                    <td>{respuestaCargaMasiva.Plantas}</td>
                                </tr>
                                <tr>
                                    <th>Edificios</th>
                                    <td>{respuestaCargaMasiva.Edificios}</td>
                                </tr>
                                <tr>
                                    <th>Orientaciones</th>
                                    <td>
                                        {respuestaCargaMasiva.Orientaciones}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Terrenos</th>
                                    <td>{respuestaCargaMasiva.Terrenos}</td>
                                </tr>
                                <tr>
                                    <th>Unidades</th>
                                    <td>{respuestaCargaMasiva.Unidades}</td>
                                </tr>
                                <tr>
                                    <th>Estacionamientos</th>
                                    <td>
                                        {respuestaCargaMasiva.Estacionamientos}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Bodegas</th>
                                    <td>{respuestaCargaMasiva.Bodegas}</td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <div>
                            Debes tener preparado el archivo{" "}
                            <i>
                                "storage/app/proyectos/{proyecto.slug}
                                /carga_proyecto.xlsx"
                            </i>{" "}
                            con los datos del proyecto y las siguientes carpetas
                            con las imágenes correspondientes:
                            <br />
                            <i>
                                "storage/app/proyectos/{proyecto.slug}
                                /edificios"
                            </i>
                            <br />
                            <i>
                                "storage/app/proyectos/{proyecto.slug}/plantas"
                            </i>
                            <br />
                            <i>
                                "storage/app/proyectos/{proyecto.slug}/unidades"
                            </i>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setModalAdvertencia(false);
                            setRespuestaCargaMasiva(null);
                        }}
                    >
                        {!respuestaCargaMasiva ? (
                            <span>Cancelar</span>
                        ) : (
                            <span>Cerrar</span>
                        )}
                    </Button>
                    {!respuestaCargaMasiva ? (
                        <Button
                            variant="primary"
                            onClick={() => {
                                cargaMasiva(proyecto.id);
                            }}
                        >
                            Realizar Carga
                        </Button>
                    ) : null}
                </Modal.Footer>
            </Modal>

            <SolicitudReporte
                url={`cotizacion/report/${proyecto.id}`}
                data={{ proyecto }}
                filters={
                    user.role == "dios" ? ["proyecto", "fechas"] : ["fechas"]
                }
                proyectos={proyectos}
                fileName={`Reporte_Cotizaciones_${
                    typeof proyecto.nombre != "undefined"
                        ? proyecto.nombre.replaceAll(" ", "-")
                        : ""
                }`}
                modal={modalReporte}
                setModal={setModalReporte}
                title="Reporte Cotizaciones"
            ></SolicitudReporte>

            <SolicitudReporte
                url={`${formulariosUrl}/formularios/reporte`}
                data={{ proyecto }}
                filters={["proyecto", "fechas"]}
                proyectos={proyectos}
                fileName={`Reporte_Formularios_${
                    typeof proyecto.nombre != "undefined"
                        ? proyecto.nombre.replaceAll(" ", "-")
                        : ""
                }`}
                modal={modalReporteFormulario}
                setModal={setModalReporteFormulario}
                title="Reporte Formularios"
            ></SolicitudReporte>
        </div>
    );
};

export default Proyectos;
