import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import useQuerys from "../../hooks/useQuerys";
import CheckboxGroup from "../util/form/CheckboxGroup";
import Input from "../util/form/Input";
import InputFile from "../util/form/InputFile";
import Select from "../util/form/Select";
import Switch from "../util/form/Switch";
import { FaSave } from "react-icons/fa";
import Img from "../util/Img";

const ProyectoForm = ({ formik }) => {
    const { getItems } = useQuerys();

    const nombre = useRef(null);
    const logo = useRef(null);
    const imagen = useRef(null);

    const [empresas, setEmpresas] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [ejecutivos, setEjecutivos] = useState([]);
    const [supervisores, setSupervisores] = useState([]);
    const [regiones, setRegiones] = useState([]);
    const [comunas, setComunas] = useState([]);

    useEffect(async () => {
        setTipos(await getItems("tipos"));
        setEmpresas(await getItems("empresas/get-all"));
        setUsuarios(await getItems("usuarios/get-users"));
        setRegiones(await getItems("regiones"));
    }, []);

    useEffect(() => {
        if (empresas.length && formik.values.empresa_id) {
            seleccionaEmpresa(formik.values.empresa_id);
        }
    }, [empresas]);

    useEffect(() => {
        if (regiones.length && formik.values.region_id) {
            seleccionaRegion(formik.values.region_id);
        }
    }, [regiones]);

    const seleccionaEmpresa = (empresa_id) => {
        const personal = [];

        if (empresas.length) {
            empresas.map((empresa) => {
                if (empresa.id == empresa_id) {
                    if (empresa.usuarios.length) {
                        empresa.usuarios.map((usuario) => {
                            personal.push({
                                id: usuario.id,
                                nombre: usuario.name + " " + usuario.lastname,
                            });
                        });
                    }
                }
            });
        }
        setEjecutivos(personal);
    };

    const seleccionaRegion = (region_id) => {
        if (regiones.length) {
            regiones.map((region) => {
                if (region.id == region_id) {
                    setComunas(region.comunas);
                }
            });
        }
    };

    return (
        <div>
            <Form id="clientes-form" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-lg-4 mb-3">
                        <Input
                            name="nombre"
                            label="Nombre Proyecto"
                            formik={formik}
                            ref={nombre}
                        ></Input>
                    </div>

                    <div className="col-lg-4 mb-3">
                        <Select
                            name="empresa_id"
                            label="Empresa"
                            options={empresas}
                            optionKey="id"
                            optionValue="id"
                            optionText="nombre"
                            onChange={seleccionaEmpresa}
                            zeroOption="Selecciona Empresa"
                            formik={formik}
                        ></Select>
                    </div>

                    <div className="col-lg-4 mb-3">
                        <Select
                            name="tipo_id"
                            label="Tipo Proyecto"
                            options={tipos}
                            optionKey="id"
                            optionValue="id"
                            optionText="nombre"
                            zeroOption="Selecciona Tipo de Proyecto"
                            formik={formik}
                        ></Select>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <Input name="url" label="Url" formik={formik}></Input>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <Input
                            name="email"
                            label="Email"
                            type="email"
                            formik={formik}
                        />
                    </div>

                    <div className="col-lg-6 mb-3">
                        <Input
                            name="valor_reserva"
                            label="Valor Reserva"
                            formik={formik}
                        ></Input>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <Input
                            name="entrega"
                            label="Entrega"
                            formik={formik}
                        ></Input>
                    </div>

                    <div className="col-lg-3 mb-3">
                        <Select
                            name="region_id"
                            label="Región"
                            options={regiones}
                            optionKey="id"
                            optionValue="id"
                            optionText="nombre"
                            onChange={seleccionaRegion}
                            zeroOption="Selecciona Region"
                            formik={formik}
                        ></Select>
                    </div>

                    <div className="col-lg-3 mb-3">
                        <Select
                            name="comuna_id"
                            label="Comunas"
                            options={comunas}
                            optionKey="id"
                            optionValue="id"
                            optionText="nombre"
                            zeroOption="Selecciona Comuna"
                            formik={formik}
                        ></Select>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <Input
                            name="direccion"
                            label="Dirección"
                            formik={formik}
                        ></Input>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <InputFile
                            name="logo"
                            label="Logo"
                            ref={logo}
                            formik={formik}
                        />
                    </div>

                    <div className="col-lg-6 mb-3">
                        <InputFile
                            name="imagen"
                            label="Imágen"
                            ref={imagen}
                            formik={formik}
                        />
                    </div>

                    <div className="col-lg-6 mb-3">
                        <Select
                            name="supervisor_id"
                            label="Supervisor"
                            options={ejecutivos}
                            optionKey="id"
                            optionValue="id"
                            optionText="nombre"
                            zeroOption="Selecciona Supervisor"
                            formik={formik}
                        ></Select>
                    </div>

                    {ejecutivos.length ? (
                        <div className="col-lg-6 mb-3">
                            {
                                <CheckboxGroup
                                    name="ejecutivos"
                                    label="Ejecutivos"
                                    items={ejecutivos}
                                    formik={formik}
                                />
                            }
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                <button
                    className=" btn btn-lg btn-primary btn-guardar mt-4 "
                    type="submit"
                >
                    Guardar
                    <FaSave></FaSave>
                </button>
            </Form>
        </div>
    );
};

export default ProyectoForm;
