import React, { useState, useEffect } from "react";
import useQuerys from "../../hooks/useQuerys";
import { useFormik } from "formik";

import UsuarioForm from "./UsuarioForm";
import Validation from "./UsuarioValidation";
import { Button, Table, Modal, Form } from "react-bootstrap";
import { FaPlus, FaPen, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { useStore } from "../../store/StoreProvider";

const Usuarios = () => {
    const { theme } = useStore();
    const { getItems, updateItem, storeItem } = useQuerys();
    const [initialValues, setInitialValues] = useState({
        id: null,
        name: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        empresa_id: 1,
        role_id: 1,
        active: true,
    });

    const [usuarios, setUsuarios] = useState([]);
    const [modal, setModal] = useState(false);
    const [empresas, setEmpresas] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(async () => {
        setUsuarios(await getItems("usuarios/get-all"));
        setEmpresas(await getItems("empresas/get-all"));
        setRoles(await getItems("usuarios/get-roles"));
    }, []);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Validation,
        onSubmit: async (values) => {
            const formData = new FormData();

            formData.append("name", values.name);
            formData.append("lastname", values.lastname);
            formData.append("email", values.email);
            formData.append("phone", values.phone);
            formData.append("password", values.password);
            formData.append(
                "password_confirmation",
                values.password_confirmation
            );
            formData.append("empresa_id", values.empresa_id);
            formData.append("role_id", values.role_id);
            formData.append("active", values.active);

            let responseData = null;
            if (values.id) {
                responseData = await updateItem(
                    `usuarios/${values.id}`,
                    formData,
                    formik
                );
                if (responseData) {
                    updateData(responseData);
                }
            } else {
                responseData = await storeItem("usuarios", formData, formik);
                if (responseData) {
                    if (responseData) {
                        setUsuarios([...usuarios, responseData]);
                    }
                }
            }

            if (responseData && !responseData.errors) {
                setModal(false);
            }
        },
    });

    const setValues = (item) => {
        formik.setValues({
            id: item.id,
            name: item.name,
            lastname: item.lastname,
            email: item.email,
            phone: item.phone,
            password: "",
            password_confirmation: "",
            empresa_id: item.empresa_id,
            role_id: item.role_id,
            active: item.active,
        });
    };

    const updateData = async (itemNewData) => {
        if (typeof itemNewData != "undefined") {
            const itemIndex = usuarios.findIndex(
                (p) => p.id === itemNewData.id
            );

            setUsuarios((usuarios) => {
                return [
                    ...usuarios.slice(0, itemIndex),
                    itemNewData,
                    ...usuarios.slice(itemIndex + 1),
                ];
            });
        } else {
            setUsuarios(await getItems("usuarios/get-all"));
        }
    };

    const toggleActive = async (item) => {
        item.active = !item.active;

        const responseData = await updateItem(
            `usuarios/${item.id}`,
            item,
            formik
        );

        updateData(responseData);
    };

    return (
        <div>
            <Button
                className="plus"
                onClick={() => {
                    setModal(true);
                }}
            >
                <FaPlus></FaPlus>
            </Button>
            <h2 className="page-title">Usuarios</h2>

            <Table variant={theme} responsive>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                        <th>Empresa</th>
                        <th className="d-none d-md-table-cell">Rol</th>
                        <th>Activo</th>
                        <th className="d-none d-md-table-cell"></th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.map((usuario) => {
                        return (
                            <tr key={usuario.id}>
                                <td>
                                    <a
                                        className="d-block d-md-none"
                                        href="javascript:void(null);"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setValues(usuario);
                                            setModal(true);
                                        }}
                                    >
                                        {usuario.name} {usuario.lastname}
                                    </a>
                                    <span className="d-none d-md-block">
                                        {usuario.name} {usuario.lastname}
                                    </span>
                                </td>
                                <td>
                                    <a href={`mailto:${usuario.email}`}>
                                        <span className="d-block d-md-none">
                                            <FaEnvelope></FaEnvelope>
                                        </span>
                                        <span className="d-none d-md-block">
                                            {usuario.email}
                                        </span>
                                    </a>
                                </td>
                                <td>
                                    <a href={`tel:+56${usuario.phone}`}>
                                        <span className="d-block d-md-none">
                                            <FaPhoneAlt></FaPhoneAlt>
                                        </span>
                                        <span className="d-none d-md-block">
                                            {`+56 ${usuario.phone}`}
                                        </span>
                                    </a>
                                </td>

                                <td>{usuario.empresa_name}</td>
                                <td className="d-none d-md-table-cell">
                                    {usuario.role_name}
                                </td>

                                <td>
                                    <Form.Check
                                        type="switch"
                                        checked={usuario.active}
                                        onChange={() => {
                                            toggleActive(usuario);
                                        }}
                                    ></Form.Check>
                                </td>
                                <td className="d-none d-md-table-cell">
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            setValues(usuario);
                                            setModal(true);
                                        }}
                                    >
                                        <FaPen></FaPen>
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Modal
                show={modal}
                onHide={() => {
                    setModal(false);
                    formik.setValues(initialValues);
                }}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {formik.values.id ? "Editar Usuario" : "Nuevo Usuario"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UsuarioForm
                        formik={formik}
                        empresas={empresas}
                        roles={roles}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Usuarios;
