import React from "react";
import { useStore } from "../../store/StoreProvider";

import "./Clientes.scss";
import Paginados from "./Paginados";
import Paneles from "./Paneles";

const Clientes = () => {
    const { user } = useStore();

    return (
        <>
            {user.role == "ejecutivo" ? (
                <Paneles></Paneles>
            ) : (
                <Paginados></Paginados>
            )}
        </>
    );
};

export default Clientes;
