import React, { useEffect, useState } from "react";
import useNumbers from "../../hooks/useNumbers";
import "./KpiCard.scss";
import { FaArrowUp, FaArrowDown, FaMinus } from "react-icons/fa";

const KpiCard = ({ title, big, small = null, text, textSmall, withCalc = false }) => {
    const { toNumber } = useNumbers();
    const [difference, setDifference] = useState(0);
    const [color, setColor] = useState("");
    const [arrow, setArrow] = useState("");
    useEffect(() => {
        if (withCalc) {

            let diff = 0;

            switch (true) {
                case big > small && small > 0:
                    diff = 100 - (big * 100) / small;
                    break;

                case big > small && small == 0:
                    diff = 100;
                    break;

                case big == 0 && small == 0:
                    diff = 0;
                    break;

                case big < small:
                    diff = (100 - (big * 100) / small) * -1;
                    break;
            }

            setDifference(diff);
        }
    }, []);

    useEffect(() => {
        if (difference == 0) {
            setColor("success");
            setArrow(<FaMinus></FaMinus>);
        } else if (difference > 0) {
            setColor("success");
            setArrow(<FaArrowUp></FaArrowUp>);
        } else {
            setColor("danger");
            setArrow(<FaArrowDown></FaArrowDown>);
        }
    }, [difference]);

    return (
        <div className="card  text-dark bg-light kpi-card">
            <div className="card-header">{title}</div>
            <div className="card-body">
                <div className="card-text">
                    <div className="data">
                        {small != null && <div className="small">
                            {textSmall} ({small})
                        </div>}

                        <div className="big">
                            <small>{text}</small>
                            {big}
                        </div>
                        {withCalc == true &&
                            <div className="small">
                                <span className={`difference text-${color}`}>
                                    {arrow} {toNumber(difference, 1)}%
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KpiCard;
