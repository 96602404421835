import React, { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import Input from "../util/form/Input";
import Select from "../util/form/Select";
import InputPrepend from "../util/form/InputPrepend";
import { FaSave } from "react-icons/fa";

const UsuarioForm = ({ formik, empresas, roles }) => {
    const name = useRef(null);

    useEffect(async () => {
        name.current.focus();
    }, []);

    return (
        <div>
            <Form id="usuario-form" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-lg-6">
                        <Input
                            name="name"
                            label="Nombre"
                            type="text"
                            formik={formik}
                            ref={name}
                        />
                    </div>
                    <div className="col-lg-6">
                        <Input
                            name="lastname"
                            label="Apellido"
                            type="text"
                            formik={formik}
                        />
                    </div>

                    <div className="col-lg-6">
                        <Input
                            name="email"
                            label="Email"
                            type="email"
                            formik={formik}
                        />
                    </div>

                    <div className="col-lg-6">
                        <InputPrepend
                            name="phone"
                            label="Teléfono"
                            type="text"
                            prependText="+56"
                            formik={formik}
                        />
                    </div>

                    <div className="col-lg-6">
                        <Input
                            name="password"
                            label="Contraseña"
                            type="password"
                            formik={formik}
                        />
                    </div>

                    <div className="col-lg-6">
                        <Input
                            name="password_confirmation"
                            label="Repite Contraseña"
                            type="password"
                            formik={formik}
                        />
                    </div>

                    <div className="col-lg-6">
                        <Select
                            name="empresa_id"
                            label="Empresa"
                            options={empresas}
                            optionKey="id"
                            optionValue="id"
                            optionText="nombre"
                            formik={formik}
                        ></Select>
                    </div>

                    <div className="col-lg-6">
                        <Select
                            name="role_id"
                            label="Rol"
                            options={roles}
                            optionKey="id"
                            optionValue="id"
                            optionText="name"
                            formik={formik}
                        ></Select>
                    </div>
                    <div className="col-md-6 offset-md-3">
                        <button
                            className=" btn btn-lg btn-primary btn-guardar mt-4 "
                            type="submit"
                        >
                            Guardar <FaSave></FaSave>
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default UsuarioForm;
