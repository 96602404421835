import React from "react";
import { Form, FloatingLabel, InputGroup } from "react-bootstrap";

import "./InputPrepend.scss";

const InputPrepend = React.forwardRef(
    ({ name, label, type, formik, prependText, onChange = null }, ref) => {
        let handleChange = formik.handleChange;
        if (onChange) {
            handleChange = onChange;
        }

        return (
            <Form.Group controlId={name} className="mb-3 position-relative">
                <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                        {prependText}
                    </InputGroup.Text>

                    <FloatingLabel
                        label={label}
                        className={
                            formik.touched[name] && formik.errors[name]
                                ? "is-invalid"
                                : "is-valid"
                        }
                    >
                        <Form.Control
                            type={type}
                            name={name}
                            ref={ref}
                            placeholder="Name"
                            onChange={handleChange}
                            value={formik.values[name]}
                            onBlur={formik.handleBlur}
                            isInvalid={
                                formik.touched[name] && formik.errors[name]
                            }
                        />
                        {formik.touched[name] && formik.errors[name] ? (
                            <Form.Control.Feedback type="invalid" tooltip>
                                {formik.errors[name]}
                            </Form.Control.Feedback>
                        ) : null}
                    </FloatingLabel>
                </InputGroup>
            </Form.Group>
        );
    }
);

export default InputPrepend;
