import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import useQuerys from "../../hooks/useQuerys";
import InputFile from "../util/form/InputFile";
import Select from "../util/form/Select";

const CargaMasiva = ({ formik }) => {
    const { getItems } = useQuerys();

    const [proyectos, setProyectos] = useState([]);

    useEffect(async () => {
        setProyectos(await getItems("proyectos/get-all"));
    }, []);

    const archivo = useRef(null);

    return (
        <Form id="carga-masiva-clientes" onSubmit={formik.handleSubmit}>
            <h5>Plantilla</h5>
            <p>
                Puedes descargar la plantilla para la carga masiva de clientes
                haciendo click <a href="/docs/carga_clientes.xlsx">AQUÍ</a>
            </p>
            <h5>Carga</h5>
            <div className="row">
                <div className="col-md-5 offset-md-1">
                    <InputFile
                        ref={archivo}
                        name="archivo"
                        label="Archivo"
                        preview={false}
                        formik={formik}
                    ></InputFile>
                </div>
                <div className="col-md-5">
                    <Select
                        name="proyecto_id"
                        label="Selecciona Proyecto"
                        options={proyectos}
                        optionKey="id"
                        optionValue="id"
                        optionText="nombre"
                        zeroOption="Proyecto"
                        formik={formik}
                    ></Select>

                    <button
                        className=" btn btn-lg btn-primary btn-guardar mt-4 "
                        type="submit"
                    >
                        Guardar
                        <FaSave></FaSave>
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default CargaMasiva;
