import * as Yup from "yup";
import checkPhone from "../validation/checkPhone";
import checkRut from "../validation/checkRut";

const Validation = Yup.object().shape({
    nombre: Yup.string()
        .min(2, "Muy Corto")
        .max(250, "Muy Largo")
        .required("Requerido"),

    apellido: Yup.string()
        .min(2, "Muy Corto")
        .max(250, "Muy Largo")
        .required("Requerido"),

    email: Yup.string()
        .email("Email no válido")
        .max(250, "Muy Largo")
        .required("Requerido"),

    fono: Yup.string()
        .min(5, "Muy Corto")
        .max(12, "Muy Largo")
        .required("Required")
        .matches(/^[0-9]+$/, "Sólo números"),

    rut: Yup.string()
        .min(7, "Muy Corto")
        .max(12, "Muy Largo")
        .required("Requerido")
        .test("rut", "Rut no válido", (val) => {
            return checkRut(val);
        }),
});

export default Validation;
