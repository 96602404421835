import React, { useRef } from "react";
import "./Login.scss";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Input from "../util/form/Input";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";

const ForgotPassword = () => {
    const { forgotPassword } = useAuth();

    const email = useRef(null);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: (values) => {
            forgotPassword(values.email);
        },
    });
    return (
        <div className="form-signin">
            <Form noValidate onSubmit={formik.handleSubmit}>
                <h1 className="h3 mb-3 fw-normal">Restablecer Contraseña</h1>
                <Input
                    name="email"
                    label="Email"
                    type="email"
                    formik={formik}
                    ref={email}
                />
                <button className="w-100 btn btn-lg btn-primary" type="submit">
                    Restablecer Contraseña
                </button>
            </Form>
            <div className="olvido">
                <Link to="/">Iniciar sesión</Link>
            </div>
        </div>
    );
};

export default ForgotPassword;
