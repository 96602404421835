import * as Yup from "yup";

const Validation = Yup.object().shape({
    empresa_id: Yup.number("No has seleccionado una empresa")
        .required("Requerido")
        .min(1, "No has seleccionado una empresa"),

    region_id: Yup.number("No has seleccionado una región")
        .required("Requerido")
        .min(1, "No has seleccionado una región"),

    comuna_id: Yup.number()
        .required("Requerido")
        .min(1, "No has seleccionado una comuna"),

    tipo_id: Yup.number()
        .required("Requerido")
        .min(1, "No has seleccionado un tipo"),

    supervisor_id: Yup.number()
        .required("Requerido")
        .min(1, "No has seleccionado un supervisor"),

    ejecutivos: Yup.array()
        .required("Requerido")
        .min(1, "No has seleccionado un ejecutivo"),

    nombre: Yup.string()
        .min(2, "Muy Corto")
        .max(250, "Muy Largo")
        .required("Requerido"),

    valor_reserva: Yup.string()
        .min(2, "Muy Corto")
        .max(250, "Muy Largo")
        .required("Requerido"),

    entrega: Yup.string().min(2, "Muy Corto").max(250, "Muy Largo").nullable(),

    email: Yup.string()
        .email("Email no válido")
        .max(250, "Muy Largo")
        .required("Requerido"),

    direccion: Yup.string()
        .min(2, "Muy Corto")
        .max(250, "Muy Largo")
        .required("Requerido"),

    /*   valor_reserva: Yup.string()
        .matches(/^[0-9]+$/, "Sólo números")
        .required("Required"), */

    url: Yup.string()
        .matches(
            /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
            "Url incorrecto"
        )
        .required("Requerido"),

    activo: Yup.boolean(),
});

export default Validation;
