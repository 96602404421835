import React, { useEffect } from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useDispatch, useStore } from "../../store/StoreProvider";
import { types } from "../../store/storeReducer";

const ProtectedRoute = () => {
    const { auth, urlRequired } = useStore();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!urlRequired) {
            dispatch({
                type: types.urlRequired,
                payload: pathname,
            });
        }
    }, []);

    if (!auth) {
        return <Navigate to="/" />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
