import React, { ChangeEventHandler, useState } from "react";
import { useEffect } from "react";
import useStorage from "../../hooks/useStorage";
import { useDispatch } from "../../store/StoreProvider";
import { types } from "../../store/storeReducer";
import "./DarkMode.scss";

const DarkMode = ({ menu = false }) => {
    const { setItem, getItem } = useStorage();
    const dispatch = useDispatch();

    const [savedTheme, setSavedTheme] = useState(getItem("theme"));
    const [prefersDark, setPrefersDark] = useState(
        window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches
    );
    const [defaultDark, setdefaultDark] = useState(
        savedTheme === "dark" || (savedTheme === null && prefersDark)
    );

    useEffect(() => {
        if (defaultDark) {
            setDark();
        }
    }, []);

    const setDark = () => {
        setItem("theme", "dark");
        dispatch({
            type: types.setThemeDark,
        });
        document.documentElement.setAttribute("data-theme", "dark");
    };

    const setLight = () => {
        setItem("theme", "light");
        dispatch({
            type: types.setThemeLight,
        });
        document.documentElement.setAttribute("data-theme", "light");
    };

    // const changeTheme = ;

    return (
        <div className="toggle-theme-wrapper">
            <span>☀️</span>
            <label className="toggle-theme" htmlFor="checkbox">
                <input
                    type="checkbox"
                    id="checkbox"
                    onChange={(e) => {
                        if (e.target.checked) {
                            setDark();
                        } else {
                            setLight();
                        }
                        if (menu) {
                            dispatch({ type: types.menuToggle });
                        }
                    }}
                    defaultChecked={defaultDark}
                />
                <div className="slider round"></div>
            </label>
            <span>🌒</span>
        </div>
    );
};

export default DarkMode;
