import React from "react";
import { Form, FloatingLabel } from "react-bootstrap";

const Input = React.forwardRef(
    ({ name, label, type, formik, onChange = null, onKeyUp = null }, ref) => {
        let handleChange = formik.handleChange;
        if (onChange) {
            handleChange = onChange;
        }

        return (
            <Form.Group controlId={name} className="mb-3 position-relative">
                {type == "hidden" ? (
                    <Form.Control
                        type={type}
                        name={name}
                        ref={ref}
                        placeholder=""
                        onChange={handleChange}
                        onKeyUp={onKeyUp}
                        value={
                            formik.values[name] && formik.values[name] != "null"
                                ? formik.values[name]
                                : ""
                        }
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched[name] && formik.errors[name]}
                    />
                ) : (
                    <FloatingLabel
                        label={label}
                        className={
                            formik.touched[name] && formik.errors[name]
                                ? "is-invalid"
                                : "is-valid"
                        }
                    >
                        <Form.Control
                            type={type}
                            name={name}
                            ref={ref}
                            placeholder=""
                            onChange={handleChange}
                            onKeyUp={onKeyUp}
                            value={
                                formik.values[name] &&
                                formik.values[name] != "null"
                                    ? formik.values[name]
                                    : ""
                            }
                            onBlur={formik.handleBlur}
                            isInvalid={
                                formik.touched[name] && formik.errors[name]
                            }
                        />
                        {formik.touched[name] && formik.errors[name] ? (
                            <Form.Control.Feedback type="invalid" tooltip>
                                {formik.errors[name]}
                            </Form.Control.Feedback>
                        ) : null}
                    </FloatingLabel>
                )}
            </Form.Group>
        );
    }
);

export default Input;
