import React, { Fragment, useState, useEffect } from "react";
import { useStore } from "../../store/StoreProvider";

import "./Loading.scss";

const Loading = () => {
    const store = useStore();
    const { loading } = store;
    // const dispatch = useDispatch()

    /* useEffect(() => {
        setProps(p);
    }, [p]); */
    return (
        <Fragment>
            <div className={`loading-cover ${loading ? "show" : "hide"}`}>
                <div className="spinner-grow text-light" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </div>
        </Fragment>
    );
};

export default Loading;
