import React, { useState } from "react";

import { useDispatch, useStore } from "../../store/StoreProvider";
import { types } from "../../store/storeReducer";
import { Link } from "react-router-dom";

import "./Header.scss";
import { Nav, Navbar, Container } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import Sidebar from "./Sidebar";
import DarkMode from "./DarkMode";

const Header = () => {
    const dispatch = useDispatch();
    const { auth } = useStore();
    const { logOut } = useAuth();

    const [home, setHome] = useState(auth ? "/dashboard" : "/");

    const LogOut = () => {
        logOut();
    };

    return (
        <Navbar
            id="navbar"
            sticky="top"
            expand={false}
            className="flex-md-nowrap p-0 shadow"
        >
            <Container fluid>
                <Link
                    to={home}
                    className="navbar-brand col-md-3 col-lg-2 me-0 px-3"
                >
                    Cotizador
                </Link>

                {!auth && <DarkMode> </DarkMode>}

                {auth && (
                    <button
                        aria-controls="offcanvasNavbar"
                        type="button"
                        aria-label="Toggle navigation"
                        className="navbar-toggler"
                        onClick={() => {
                            dispatch({ type: types.menuToggle });
                        }}
                    >
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </button>
                )}

                {auth && <Sidebar></Sidebar>}

                {/*
                <Navbar.Toggle
                    aria-controls="sidebarMenu"
                    onClick={() => {
                        dispatch({ type: types.menuToggle });
                    }}
                />
                <Nav className="navbar-nav d-none d-md-block">
                    {auth && (
                        <Nav.Link href="#" onClick={LogOut}>
                            Sign out
                        </Nav.Link>
                    )}
                </Nav> */}
            </Container>
        </Navbar>
    );
};

export default Header;
