import React, { useState, useEffect } from "react";
import { useDispatch } from "../../store/StoreProvider";
import Axios from "../../config/Axios";
import axios from "axios";
import Cookies from "js-cookie";
import { types } from "../../store/storeReducer";
import useStorage from "../../hooks/useStorage";
import useQuerys from "../../hooks/useQuerys";
import KpiCard from "../util/KpiCard";
import formulariosUrl from "../../config/formulariosUrl";

import "./Dashboard.scss";

const useFunctions = () => {
    const getClient = async (setIsLogged) => {
        await Axios.get("client")
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    setIsLogged(false);
                }
            });
    };

    return { getClient };
};

const Dashboard = () => {
    const { deleteToken, deleteUser } = useStorage();
    const dispatch = useDispatch();
    const [isLogged, setIsLogged] = useState(true);
    // const { getClient } = useFunctions();
    const [cotizaciones, setCotizaciones] = useState([]);
    const [envios, setEnvios] = useState([]);
    const [slugs, setSlugs] = useState([]);
    const { getItems } = useQuerys();

    useEffect(async () => {
        dispatch({
            type: types.loadingShow,
        });
        // await getClient(setIsLogged);
        dispatch({
            type: types.loadingHide,
        });
    }, []);

    useEffect(async () => {
        if (!isLogged) {
            deleteToken();
            deleteUser();
            dispatch({ type: types.authLogout });
            dispatch({
                type: types.loadingHide,
            });
            console.log("chao");
            return false;
        }
        setCotizaciones(await getItems("cotizaciones"));
    }, [isLogged]);

    useEffect(() => {
        if (cotizaciones.length) {
            let slugsHelper = [];
            cotizaciones.map((item) => {
                slugsHelper.push(item.slug);
            });
            setSlugs(slugsHelper);
        }
    }, [cotizaciones]);

    useEffect(() => {
        if (slugs.length) {
            const data = { slugs };
            axios
                .post(`${formulariosUrl}/formularios/enviados`, data)
                .then((response) => {
                    setEnvios(response.data);
                });
        }
    }, [slugs]);

    return (
        <div>
            <h2 className="page-title">Dashboard</h2>
            <div id="kpis-cotizaciones">
                <h4>Cotizaciones</h4>
                <div className="kpis">
                    {cotizaciones.map((proyecto) => {
                        return (
                            <KpiCard
                                key={proyecto.id}
                                title={proyecto.nombre}
                                big={proyecto.qCotizacionesActual}
                                small={proyecto.qCotizacionesAnterior}
                                textSmall="Mes Anterior"
                                text="Mes Actual"
                                withCalc={true}
                            ></KpiCard>
                        );
                    })}
                </div>
            </div>

            <div id="kpis-envios">
                <h4>Envíos Contactos</h4>
                <div className="kpis">
                    {envios.map((envio) => {
                        return (
                            <KpiCard
                                key={envio.id}
                                title={envio.nombre}
                                big={envio.qEnviosActual}
                                small={envio.qEnviosAnterior}
                                textSmall="Envíos Mes Anterior"
                                text="Envíos Mes Actual"
                                withCalc={true}
                            ></KpiCard>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
