import React from "react";
import { Form } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import Textarea from "../util/form/Textarea";
import "./Comentarios.scss";
var moment = require("moment");
moment.locale("es");

const Comentarios = ({ cliente, formik }) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <ul className="comentarios">
                        {cliente.comentarios.map((comentario) => {
                            return (
                                <li key={comentario.id} className="comentario">
                                    {comentario.comentarios}
                                    <small
                                        style={{
                                            display: "block",
                                            textAlign: "right",
                                        }}
                                    >
                                        <i className="ejecutivo">
                                            por:{" "}
                                            <strong>
                                                {comentario.ejecutivo.name}{" "}
                                                {comentario.ejecutivo.lastname}
                                            </strong>{" "}
                                            (
                                            {moment(
                                                comentario.created_at
                                            ).format("DD-MM-YYYY HH:mm")}
                                            )
                                        </i>
                                    </small>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="col-md-6">
                    <h4>Nuevo Comentario</h4>
                    <Form id="comentarios-form" onSubmit={formik.handleSubmit}>
                        <Textarea
                            name="comentarios"
                            label="Comentario"
                            formik={formik}
                        ></Textarea>
                        <button
                            className=" btn btn-lg btn-primary btn-guardar mt-4 "
                            type="submit"
                        >
                            Guardar
                            <FaSave></FaSave>
                        </button>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default Comentarios;
