import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { FaFilter, FaTimes } from "react-icons/fa";

const PageTitle = ({
    title,
    withFilter = false,
    filter = null,
    setFilter = false,
    withFiltroEjecutivo = false,
    ejecutivos = [],
    ejecutivo = {},
    onChangeEjecutivo = null,
    withFiltroProyecto = false,
    proyectos = [],
    proyecto = {},
    onChangeProyecto = null,
}) => {
    return (
        <div className="cabecera">
            <h2 className="page-title">{title}</h2>

            {withFiltroEjecutivo && (
                <div className="selector-ejecutivo">
                    <Form.Group
                        controlId="ejecutivo"
                        className=" position-relative"
                    >
                        <FloatingLabel label="Ejecutivo">
                            <Form.Select
                                onChange={(e) => {
                                    onChangeEjecutivo(e);
                                }}
                                value={ejecutivo.id}
                            >
                                <option value="all">Todos</option>
                                <option value="sin-ejecutivo">
                                    Sin Ejecutivo
                                </option>
                                {ejecutivos.map((e) => {
                                    return (
                                        <option key={e.id} value={e.id}>
                                            {`${e.name} ${e.lastname}`}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                </div>
            )}

            {withFiltroProyecto && (
                <div className="selector-proyecto">
                    <Form.Group
                        controlId="proyecto"
                        className=" position-relative"
                    >
                        <FloatingLabel label="Proyecto">
                            <Form.Select
                                onChange={(e) => {
                                    onChangeProyecto(e);
                                }}
                                value={proyecto.id}
                            >
                                {proyectos.map((proyecto) => {
                                    return (
                                        <option
                                            key={proyecto.id}
                                            value={proyecto.id}
                                        >
                                            {proyecto.nombre}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                </div>
            )}

            {withFilter && (
                <div className="filtro">
                    <FloatingLabel label="Fitro">
                        <Form.Control
                            type="text"
                            onChange={(e) => {
                                setFilter(e.target.value);
                            }}
                            value={filter}
                        />
                    </FloatingLabel>
                </div>
            )}
        </div>
    );
};

export default PageTitle;
