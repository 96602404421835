import React, { useEffect, useState } from "react";

const Img = ({ img, alt = null, w = null, className = "img-fluid" }) => {
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        setImageUrl(`/storage/${img}`);
    }, [img]);

    return (
        <img
            key={Date.now()}
            src={imageUrl}
            className={className}
            width={w}
            alt={alt}
        />
    );
};

export default Img;
