import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import useQuerys from "../../hooks/useQuerys";
import Input from "../util/form/Input";
import Select from "../util/form/Select";
import { FaSave } from "react-icons/fa";
import InputPrepend from "../util/form/InputPrepend";
import { useStore } from "../../store/StoreProvider";

const ProyectoForm = ({ formik }) => {
    const { getItems } = useQuerys();
    const { user } = useStore();

    const nombre = useRef(null);

    const [empresas, setEmpresas] = useState([]);
    const [ejecutivos, setEjecutivos] = useState([]);
    const [proyectos, setProyectos] = useState([]);

    useEffect(async () => {
        setEmpresas(await getItems("empresas/get-all"));
        nombre.current.focus();
    }, []);

    useEffect(() => {
        if (empresas.length && formik.values.empresa_id) {
            seleccionaEmpresa(formik.values.empresa_id);
        }
    }, [empresas]);

    const seleccionaEmpresa = (empresa_id) => {
        const pro = [];
        if (empresas.length) {
            empresas.map((empresa) => {
                if (empresa.id == empresa_id) {
                    if (empresa.proyectos.length) {
                        setProyectos(empresa.proyectos);
                    }
                }
            });
        }
    };

    const seleccionaProyectos = (proyecto_id) => {
        const personal = [];

        const proyecto = proyectos.find(
            (proyecto) => proyecto.id == proyecto_id
        );

        proyecto.ejecutivos.map((ejecutivo) => {
            if (!personal.find((p) => p.id == ejecutivo.id)) {
                personal.push({
                    id: ejecutivo.id,
                    nombre: ejecutivo.name + " " + ejecutivo.lastname,
                });
            }
        });

        setEjecutivos(personal);
    };

    const formatRut = (rut) => {
        return rut
            .replace(/[.-]/g, "")
            .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4")
            .toUpperCase();
    };

    return (
        <div>
            <Form id="clientes-form" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <Input
                            name="nombre"
                            label="Nombre"
                            formik={formik}
                            ref={nombre}
                        ></Input>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <Input
                            name="apellido"
                            label="Apellido"
                            formik={formik}
                        ></Input>
                    </div>

                    <div className="col-lg-4 mb-3">
                        <Input
                            name="rut"
                            label="Rut"
                            type="text"
                            formik={formik}
                            onKeyUp={(event) => {
                                formik.setFieldValue(
                                    "rut",
                                    formatRut(event.target.value)
                                );
                            }}
                        />
                    </div>

                    <div className="col-lg-4 mb-3">
                        <Input
                            name="email"
                            label="Email"
                            type="email"
                            formik={formik}
                        />
                    </div>

                    <div className="col-lg-4 mb-3">
                        <InputPrepend
                            name="fono"
                            label="Teléfono"
                            type="text"
                            prependText="+56"
                            formik={formik}
                        />
                    </div>

                    {user.role != "ejecutivo" && (
                        <>
                            <div className="col-lg-4 mb-3">
                                <Select
                                    name="empresa_id"
                                    label="Empresa"
                                    options={empresas}
                                    optionKey="id"
                                    optionValue="id"
                                    optionText="nombre"
                                    onChange={seleccionaEmpresa}
                                    zeroOption="Selecciona Empresa"
                                    formik={formik}
                                ></Select>
                            </div>

                            {proyectos.length ? (
                                <div className="col-lg-4 mb-3">
                                    <Select
                                        name="proyecto_id"
                                        label="Proyectos"
                                        options={proyectos}
                                        optionKey="id"
                                        optionValue="id"
                                        optionText="nombre"
                                        onChange={seleccionaProyectos}
                                        zeroOption="Selecciona Proyecto"
                                        formik={formik}
                                    ></Select>
                                </div>
                            ) : (
                                ""
                            )}

                            {ejecutivos.length ? (
                                <div className="col-lg-4 mb-3">
                                    <Select
                                        name="ejecutivo_id"
                                        label="Ejecutivos"
                                        options={ejecutivos}
                                        optionKey="id"
                                        optionValue="id"
                                        optionText="nombre"
                                        zeroOption="Selecciona Ejecutivo"
                                        formik={formik}
                                    ></Select>
                                </div>
                            ) : (
                                ""
                            )}
                        </>
                    )}
                </div>

                <button
                    className=" btn btn-lg btn-primary btn-guardar mt-4 "
                    type="submit"
                >
                    Guardar
                    <FaSave></FaSave>
                </button>
            </Form>
        </div>
    );
};

export default ProyectoForm;
