import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./Sidebar.scss";
import { Offcanvas, Navbar, Nav, CloseButton } from "react-bootstrap";
import { useDispatch, useStore } from "../../store/StoreProvider";
import { types } from "../../store/storeReducer";

import {
    FaUserTie,
    FaBuilding,
    FaHome,
    FaUsers,
    FaSignOutAlt,
    FaWpforms,
    FaBriefcase,
} from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import DarkMode from "./DarkMode";

const Sidebar = () => {
    const { logOut } = useAuth();
    const { menu, user, theme } = useStore();
    const store = useStore();
    const dispatch = useDispatch();
    const [variantClose, setVariantClose] = useState("white");

    useEffect(() => {
        if (theme == "light") {
            setVariantClose(null);
        } else {
            setVariantClose("white");
        }
    }, [theme]);

    return (
        <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={menu}
            variant={theme}
            onHide={() => {
                dispatch({ type: types.menuToggle });
            }}
            // scroll={true}
            // backdrop={true}
        >
            <Offcanvas.Header>
                <Offcanvas.Title id="offcanvasNavbarLabel">
                    Hola {user.name}
                </Offcanvas.Title>

                {
                    <CloseButton
                        variant={variantClose}
                        onClick={() => {
                            dispatch({ type: types.menuToggle });
                        }}
                    />
                }
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav className=" flex-grow-1 pe-3">
                    <Link
                        className="nav-link"
                        to="/dashboard"
                        onClick={() => {
                            dispatch({ type: types.menuToggle });
                        }}
                    >
                        <FaHome /> Dashboard
                    </Link>

                    <Link
                        className="nav-link"
                        to="/clientes"
                        onClick={() => {
                            dispatch({ type: types.menuToggle });
                        }}
                    >
                        <FaUserTie /> Clientes
                    </Link>

                    <Link
                        className="nav-link"
                        to="/proyectos"
                        onClick={() => {
                            dispatch({ type: types.menuToggle });
                        }}
                    >
                        <FaBriefcase /> Proyectos
                    </Link>
                    {user.role == "dios" && (
                        <Link
                            className="nav-link"
                            to="/empresas"
                            onClick={() => {
                                dispatch({ type: types.menuToggle });
                            }}
                        >
                            <FaBuilding /> Empresas
                        </Link>
                    )}

                    {/* formulario */}

                    {/* <Link
                        className="nav-link"
                        to="/new"
                        onClick={() => {
                            dispatch({ type: types.menuToggle });
                        }}
                    >
                        <FaUserTie /> Nuevo Formulario
                    </Link> */}

                    {/* <Link
                        className="nav-link"
                        to="/formularios"
                        onClick={() => {
                            dispatch({ type: types.menuToggle });
                        }}
                    >
                        <FaWpforms /> Formularios
                    </Link> */}

                    {/*   <Link
                        className="nav-link"
                        to="/statistics"
                        onClick={() => {
                            dispatch({ type: types.menuToggle });
                        }}
                    >
                        <FaUserTie /> Estadísticas
                    </Link> */}

                    {/* fin formularios */}

                    {user.role == "dios" && (
                        <Link
                            className="nav-link"
                            to="/usuarios"
                            onClick={() => {
                                dispatch({ type: types.menuToggle });
                            }}
                        >
                            <FaUsers /> Usuarios
                        </Link>
                    )}

                    <a
                        href="#"
                        className="nav-link"
                        onClick={() => {
                            dispatch({ type: types.menuToggle });
                            logOut();
                        }}
                    >
                        <FaSignOutAlt /> Salir
                    </a>
                </Nav>
                <DarkMode menu={true}></DarkMode>
            </Offcanvas.Body>
        </Navbar.Offcanvas>

        /*  <div className={`content-sidebar ${menu ? "show" : "hide"}`}>
            <Nav
                id="sidebarMenu"
                className={`col-md-3 col-lg-2 d-md-block bg-light sidebar collapsed`}
            >
                <Link
                    className="nav-link"
                    to="/dashboard"
                    onClick={() => {
                        dispatch({ type: types.menuToggle });
                    }}
                >
                    <FaHome /> Dashboard
                </Link>

                <Link
                    className="nav-link"
                    to="/proyectos"
                    onClick={() => {
                        dispatch({ type: types.menuToggle });
                    }}
                >
                    <FaBuilding /> Proyectos
                </Link>

                <Link
                    className="nav-link"
                    to="/empresas"
                    onClick={() => {
                        dispatch({ type: types.menuToggle });
                    }}
                >
                    <FaUserTie /> Empresas
                </Link>

                <Link
                    className="nav-link"
                    to="/usuarios"
                    onClick={() => {
                        dispatch({ type: types.menuToggle });
                    }}
                >
                    <FaUsers /> Usuarios
                </Link>
            </Nav>
        </div> */
    );
};

export default Sidebar;
