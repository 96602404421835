import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const Textarea = React.forwardRef(
    ({ name, label, placeholder, formik, onChange = null }, ref) => {
        let handleChange = (e) => {
            formik.setFieldValue(name, e.target.value);
        };

        if (onChange) {
            handleChange = onChange;
        }
        return (
            <Form.Group controlId={name} className="mb-3 position-relative">
                <FloatingLabel
                    label={label}
                    className={
                        formik.touched[name] && formik.errors[name]
                            ? "is-invalid"
                            : "is-valid"
                    }
                >
                    <Form.Control
                        as="textarea"
                        ref={ref}
                        name={name}
                        placeholder={placeholder}
                        style={{ height: "100px" }}
                        onChange={handleChange}
                        value={formik.values[name]}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched[name] && formik.errors[name]}
                    />
                    {formik.touched[name] && formik.errors[name] ? (
                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors[name]}
                        </Form.Control.Feedback>
                    ) : null}
                </FloatingLabel>
            </Form.Group>
        );
    }
);

export default Textarea;
