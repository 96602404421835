import { useDispatch } from "../store/StoreProvider";
import { types } from "../store/storeReducer";
import { useNavigate } from "react-router-dom";

import Axios from "../config/Axios";
import axios from "axios";

const useQuerys = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getReport = async (url, values, fileName, callBack = null) => {
        dispatch({
            type: types.loadingShow,
        });

        const options = {
            method: "POST",
            responseType: "blob",
            data: values,
            url: url,
        };
        let data = null;
        if (url.includes("http")) {
            data = axios
                .request(options)
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: "application/vnd.ms-excel",
                    });

                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);

                    dispatch({
                        type: types.messageShow,
                        payload: {
                            type: "success",
                            message: "Reporte generado",
                        },
                    });
                    if (callBack) {
                        callBack();
                    }
                    return response.data.data;
                })
                .catch((error) => {
                    let message = "";
                    // alert(error)
                    if (typeof error.response.data.message == "undefined") {
                        message = error.response.statusText;
                    } else {
                        message = error.response.data.message;
                    }
                    dispatch({
                        type: types.messageShow,
                        payload: { type: "danger", message: message },
                    });
                })
                .finally(() => {
                    dispatch({
                        type: types.loadingHide,
                    });
                });
        } else {
            data = await Axios.request(options)
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: "application/vnd.ms-excel",
                    });

                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);

                    dispatch({
                        type: types.messageShow,
                        payload: {
                            type: "success",
                            message: "Reporte generado",
                        },
                    });
                    if (callBack) {
                        callBack();
                    }
                    return response.data.data;
                })
                .catch((error) => {
                    let message = "";
                    // alert(error)
                    if (typeof error.response.data.message == "undefined") {
                        message = error.response.statusText;
                    } else {
                        message = error.response.data.message;
                    }
                    dispatch({
                        type: types.messageShow,
                        payload: { type: "danger", message: message },
                    });
                })
                .finally(() => {
                    dispatch({
                        type: types.loadingHide,
                    });
                });
        }

        return data;
    };

    const getItems = async (url, data = {}) => {
        dispatch({
            type: types.loadingShow,
        });
        const resp = await Axios.get(url, data)
            .then((response) => {
                dispatch({
                    type: types.loadingHide,
                });
                return response.data;
            })
            .catch((error) => {
                let message = "";
                if (typeof error.response.data.message == "undefined") {
                    message = error.response.statusText;
                } else {
                    message = error.response.data.message;
                }
                dispatch({
                    type: types.loadingHide,
                });
                dispatch({
                    type: types.messageShow,
                    payload: { type: "danger", message: message },
                });
                if (error.response.status == 401) {
                    return navigate("/dashboard");
                }
            });
        if (typeof resp == "undefined") {
            return [];
        }
        return resp;
    };

    const getPagination = async (url) => {
        const response = await getItems(url);

        const resp = {
            data: response.data,
            links: response.links,
            current_page: response.current_page,
            next_page_url: response.next_page_url,
            prev_page_url: response.prev_page_url,
            first_page_url: response.first_page_url,
            last_page_url: response.last_page_url,
        };
        return resp;
    };

    const storeItem = async (url, values, formik = null, callBack = null) => {
        dispatch({
            type: types.loadingShow,
        });
        const data = await Axios.post(url, values)
            .then((response) => {
                dispatch({
                    type: types.messageShow,
                    payload: {
                        type: "success",
                        message: response.data.message,
                    },
                });
                if (callBack) {
                    callBack();
                }
                return response.data.data;
            })
            .catch((error) => {
                console.log(error);
                return;
                let message = "";
                if (typeof error.response.data.message == "undefined") {
                    message = error.response.statusText;
                } else {
                    message = error.response.data.message;
                }
                dispatch({
                    type: types.messageShow,
                    payload: { type: "danger", message: message },
                });
                if (typeof error.response.data.data != "undefined") {
                    displayErrors(error.response.data.data, formik);
                }
            })
            .finally(() => {
                dispatch({
                    type: types.loadingHide,
                });
            });
        return data;
    };

    const updateItem = async (url, values, formik = null) => {
        if (typeof values.append == "undefined") {
            values._method = "put";
        } else {
            values.append("_method", "put");
        }

        dispatch({
            type: types.loadingShow,
        });
        const data = await Axios.post(url, values)
            .then((response) => {
                dispatch({
                    type: types.messageShow,
                    payload: {
                        type: "success",
                        message: response.data.message,
                    },
                });
                return response.data.data;
            })
            .catch((error) => {
                let message = "";
                if (typeof error.response.data.message == "undefined") {
                    message = error.response.statusText;
                } else {
                    message = error.response.data.message;
                }
                dispatch({
                    type: types.messageShow,
                    payload: { type: "danger", message: message },
                });
                if (typeof error.response.data.data != "undefined") {
                    displayErrors(error.response.data.data, formik);
                }
            })
            .finally(() => {
                dispatch({
                    type: types.loadingHide,
                });
            });
        return data;
    };

    const deleteItem = async (url, values) => {
        if (typeof values.append == "undefined") {
            values._method = "delete";
        } else {
            values.append("_method", "delete");
        }

        dispatch({
            type: types.loadingShow,
        });
        const data = await Axios.post(url, values)
            .then((response) => {
                dispatch({
                    type: types.messageShow,
                    payload: {
                        type: "success",
                        message: response.data.message,
                    },
                });
                return response.data.data;
            })
            .catch((error) => {
                let message = "";
                if (typeof error.response.data.message == "undefined") {
                    message = error.response.statusText;
                } else {
                    message = error.response.data.message;
                }
                dispatch({
                    type: types.messageShow,
                    payload: { type: "danger", message: message },
                });
            })
            .finally(() => {
                dispatch({
                    type: types.loadingHide,
                });
            });
        return data;
    };

    const displayErrors = (errors, formik = null) => {
        for (const value in errors) {
            if (
                formik &&
                Object.hasOwnProperty.call(errors, value) &&
                Object.hasOwnProperty.call(formik.values, value) &&
                errors[value][0]
            ) {
                const error = errors[value][0];
                formik.errors[value] = error;
            }
        }
    };

    return {
        getItems,
        storeItem,
        updateItem,
        deleteItem,
        getReport,
        getPagination,
    };
};

export default useQuerys;
