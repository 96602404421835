const useNumbers = () => {
    const toCurrency = (number, decimal = 2) => {
        return number.toLocaleString("es-CL", {
            style: "currency",
            currency: "CLP",
            minimumFractionDigits: decimal,
        });
    };

    const toUf = (number, decimal = 2) => {
        return (
            number.toLocaleString("es-CL", {
                minimumFractionDigits: decimal,
            }) + " UF"
        );
    };

    const toNumber = (number, decimal = 2) => {
        return number.toLocaleString("es-CL", {
            minimumFractionDigits: decimal,
        });
    };
    return { toCurrency, toNumber, toUf };
};

export default useNumbers;
